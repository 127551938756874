


/*------- ADD USUARIO EXPRESS PX -------*/
/*---------------------------------------*/

.div-datos-addUsPx {
    width: 100%;
    /* border: 1px solid red; */
}

.div-addPx-headerUs {
    width: 60%;
    margin: auto;
    margin-top: 1rem;
    text-align: center;
    /* border: 1px solid red; */
}

/*--- Foto -----*/

.div_content_foto_addUsPx {
    width: 60%;
    margin: auto;
    display: flex;
    padding: 0.4rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
}

.div-foto-px-addUsPx {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    /* border: 1px solid green; */
}

.div-foto-px-addUsPx img {
    width: 60%;
    margin: auto;
    /* border: 1px solid green; */
    box-shadow: 4px 4px 6px 0;
}

.div_btn_foto_addUsPx {
    width: 40%;
    margin: auto;
    /* border: 1px solid green; */
}

.div_btn_quitar_addUsPx {
    width: 20%;
    margin: auto;
    /* border: 1px solid green; */
}


/*------ Select de psic -------*/

.div-Select-table-addUsPx {
    width: 60%;
    margin: auto;
    /* border: 1px solid violet; */
}

.div-tablePsi-addUsPx {
    width: 100%;
    display: flex;
    padding: 0.6rem;
    margin-top: 0.8rem;
    margin-bottom: 0.4rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
}

.div-lbl-addUsPx {
    width: 15%;
    /* border: 1px solid green; */
}

.div-Select-addUsPx {
    width: 40%;
    /* border: 1px solid purple; */
}

.div-Select-addUsPx select {
    padding: 0.2rem;
    border-radius: 0.3rem;
    border-bottom: 1px solid gray;
}


/*------ tabla de datos -------*/

.div-table-addUsPx {
    width: 100%;
    margin: auto;
    padding: 0.6rem;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
    border: 1px solid gray;
}

.table-addUsPx {
    width: 100%;
}

/*---- filas ----*/

.add-pxUs-tr {
    width: 100%;
    padding: 0.4rem;
    /* border: 1px solid blue; */
}

/**** columnas ****/

.add-pxUs-colum1 {
    width: 15%;
    padding: 0.4rem;
    /* border: 1px solid blue; */
}

.add-pxUs-colum2 {
    width: 30%;
    padding: 0.4rem;
    /* border: 1px solid blue; */
}

.add-pxUs-colum2 input {
    width: 95%;
    padding: 0.2rem;
    font-size: 0.8rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
}

.add-pxUs-colum2 select {
    width: 95%;
    padding: 0.2rem;
    font-size: 0.8rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
}

.add-pxUs-colum2 label {
    width: 40%;
    text-align: end;
    font-size: 0.8rem;
    /* border: 1px solid gray; */
}

#mexicano {
    width: 20%;
    font-size: 0.8rem;
    /* border: 1px solid gray; */
}

#extranjero {
    width: 20%;
    font-size: 0.8rem;
    /* border: 1px solid gray; */
}


/***** slect nacionalidad *****/

.div-nacionalidad-Us {
    width: 60%;
    margin: auto;
    margin-bottom: 1rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
}

.table-nacionalidad-Us {
    width: 60%;
}


/*** btn enviar salir ***/

.div-btn-registroUs {
    width: 60%;
    margin: auto;
    padding: 0.6rem;
    margin-bottom: 2rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
}

.div-addUsPx-botones {
    width: 20%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
}

.addUsPx-btn-enviar {
    margin-right: 1rem;
    /* border: 1px solid red; */
}



