

/* ------ EDITAR RECOMENDACIÓN ------ */

.div-editRecom {
    box-sizing: border-box;
    width: 100%;
    /* border: 1px solid red; */
}

.table-recom {
    width: 100%;
    /* border: 1px solid red; */
}

#td-editRec-izq {
    width: 20%;
    /* border: 1px solid red; */
}

#td-editRec-izq label {
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.4rem;
}

#td-editRec-der {
    width: 80%;
    text-align: left;
    /* border: 1px solid red; */
}

#td-editRec-der label {
    font-size: 0.8rem;
    padding: 0.4rem;
}

.div-editRec-txtA {
    width: 100%;
    margin-top: 0.6rem;
}

.div-editRec-txtA textarea {
    box-sizing: border-box;
    width: 100%;
    font-size: 0.8rem;
    padding-top: 0.4rem;
    padding-bottom: 0.6rem;
    outline: none;
    padding-left: 0.4rem;
    /* resize: none;
    scrollbar-width: 0; /* para Firefox */
    line-height: 1rem;  /* interlineado */
    height: 150px;
    max-height: 300px;
    min-height: 100px;
}

/*
.div-editRec-txtA textarea::-webkit-scrollbar {
    width: 0;   /*ancho del scroll
}*/


