

/* -------- EDITAR PACIENTES --------*/


/* -------- Div principales -------- */

.div-1-edPx {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}

.div-titulo-edPx {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
  /* border: 1px solid red; */
}

.px_sesion_edPX {
  box-sizing: border-box;
  width: 20%;
}

#lbl-enSesion1 {
 font-weight: 600;
}

#lbl-enSesion2 {
  font-weight: 400;
}

.div_lbl_px_edPX {
  box-sizing: border-box;
  width: 80%;
  text-align: center;
  /* border: 1px solid red; */
}

.div_lbl_px_edPX label {
  font-size: 2rem;
  font-weight: 600;
}





/************ DIV TABLA ************/

.div-datos-EdPx {
  box-sizing: border-box;
  width: 60%;
  margin: auto;
  margin-bottom: 3rem;
  /* border: 1px solid red; */
}


/********** AVATAR ***********/

.div_content_foto_edPx {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.4rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
}

.div_fotoActualizar_edPx {
  box-sizing: border-box;
  margin-top: 0.4rem;
  width: 100%;
  display: flex;
  /* border: 1px solid rgb(255, 0, 0); */
}

#div_image_previa {
  box-sizing: border-box;
  width: 40%;
  display: flex;
  /* border: 1px solid rgb(76, 0, 255); */
}

#div_image_previa img {
  box-sizing: border-box;
  margin: auto;
  width: 50%;
  height: 85%;
  border-radius: 0.2rem;
  box-shadow: 0 8px 8px 0 #494949;
  /* border: 1px solid rgb(225, 0, 255); */
}

/*  cuando no hay imagen cargada no de la DB  */


.div_btn_foto_edPx {
  width: 100%;
  /* border: 1px solid rgb(76, 0, 255); */
}

.div_label_foto_edPx {
  width: 100%;
  text-align: center;
  margin-bottom: 0.4rem;
  /* border: 1px solid rgb(76, 0, 255); */
}

.div_label_foto_edPx label {
  font-weight: 600;
}



.div_sinfoto_edPx {
  box-sizing: border-box;
  width: 100%;
  font-size: 0.8rem;
  display: flex;
  /* border: 1px solid rgb(0, 89, 255); */
}

.div_selecOption_edPx {
  width: 50%;
  margin: auto;
  text-align: center;
  /* border: 1px solid rgb(255, 72, 0); */
}

.div_selecOption_edPx label {
  box-sizing: border-box;
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
}




/*-------------------------*/

.div_CargarEliminarFoto_fam {
  width: 100%;
  display: flex;
  align-items: center;
  /* border: 1px solid rgb(38, 0, 255); */
}

.div_CargarFoto_fam {
  width: 60%;
  /* border: 1px solid rgb(38, 0, 255); */
}

.div_CargarFoto_fam input {
  font-size: 0.8rem;
}

.div_eliminarFoto_fam {
  width: 40%;
  /* border: 1px solid rgb(38, 0, 255); */
}

.div_eliminarFoto_fam label {
  font-size: 0.8rem;
}


/**********************************/


/* ------- TABLA DE DATOS ------ */

.div_tabla_EdPx {
  box-sizing: border-box;
  /* border: 1px solid #002168; */
}

.div_table-EdPx {
  box-sizing: border-box;
  width: 100%;
  padding: 0.4rem;
  margin-top: 0.4rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
}

.table-EdPx {
  box-sizing: border-box;
  width: 100%;
  border-collapse: separate;
  border-spacing: 5px 10px;
  /* border: 1px solid #006809; */
}


/****** TD ********/

.edit-px-td-izq {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  background-color: rgb(255, 221, 180);
}

.edit-px-td-der1 {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}

.edit-px-td-der2 {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}

.edit-px-td-der3 {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}


/****** TD select *******/

.edPx-px-td-select {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
}

/***** INPUT ******/

.edit-px-td-der1 input {
  box-sizing: border-box;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  font-style: italic;
  padding: 0.4rem;
}

.edit-px-td-der2 input {
  box-sizing: border-box;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  font-style: italic;
  padding: 0.4rem;
}

.edit-px-td-der3 input {
  box-sizing: border-box;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  font-style: italic;
  padding: 0.4rem;
}


/********* RADIO BUTTOM **********/

.radio-container-edPx label {
  font-size: 0.8rem;
  color: #494949;
  margin-right: 0.6rem;
}


/************************/


/*  ID Select */

#select-sexo-edit,
#select-escolaridad-edit,
#select-pref_sex-edit,
#select-edo-civil-edit,
#select-edo-edit,
#select-pais-edit {
  width: 100%;
  font-size: 0.8rem;
  padding: 0.2rem;
  outline: none;
  color: #494949;
  border-radius: 0.3rem;
}


#select-sexo-edit option,
#select-escolaridad-edit option,
#select-pref_sex-edit option,
#select-edo-civil-edit option,
#select-edo-edit option,
#select-pais-edit option {
  font-size: 0.8rem;
  color: #000000;
}

/* ----------------- */


/* Div botones: ENVIAR CANCELAR*/

/*   div Padre   autoreg y botones   */

.div-autoregistro-botones-edit {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 2rem;
  /* border: 1px solid blue; */
}

.div-btn-alineacion-edit {
  box-sizing: border-box;
  margin: auto;
  width: 20%;
  display: flex;
  /* border: 1px solid blue; */
}


/* Div botones: ENVIAR CANCELAR*/
.div-edit-enviar {
  box-sizing: border-box;
  width: 50%;
  margin: auto;
  /* border: 1px solid rgb(5, 101, 165); */
}

.div-edit-cancelar {
  box-sizing: border-box;
  width: 50%;
  margin: auto;
  /* border: 1px solid rgb(5, 101, 165); */
}


#btn-enviar-edit,
#btn-cancelar-edit {
  border-radius: 0.4rem;
  border: 1px solid gray;
  cursor: pointer;
}

/*----------------------------------------*/









