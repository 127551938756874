/*------------------*/
/*------------------*/
/*------------------*/
/*------------------*/
/* ---------- VISTA CALENDARIO ------- */
/*------------------*/
/*------------------*/
/*------------------*/
/*------------------*/

.vista-calendario {
    /* position: absolute; */
    box-sizing: border-box;
    /* margin-top: 10%; */
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    /* height: 75vh; */
    /* overflow: auto;
    overflow-y: auto; */
    /* border: 1px solid red; */
    /* background-color: bisque; */
}


.encabezado-usuario {
    width: 100%;
    margin-bottom: 0.6rem;
    /* border: 1px solid green; */
}

.encabezado-usuario p {
    font-size: 1rem;
    text-align: left;
    padding: 1rem;
    font-weight: 600;
}


.encabezado-agenda {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
    /* border: 1px solid green; */
}

.encabezado-agenda label {
    font-size: 2rem;
    font-weight: 600;
}



/*------------ Div Calendario y Tabla Px -------------*/

.calendario-datos {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    /* gap: 15px; */
    /* border: 1px solid blue; */
}

.calend {
    box-sizing: border-box;
    width: 70%;
    height: 100%;
    padding: 1rem;
    margin-right: 1rem;
    border-radius: 0.3rem;
    background-color: rgb(255, 237, 216);
    border: 1px solid gray;
    box-shadow: 1px 1px 3px 0;
}


/* div padre:
  hijo1: datos-px-encabezado
  hijo2: estilo-datos-pax
  */
.datos-paciente {
    box-sizing: border-box;
    padding: 1rem;
    margin: 0;
    width: 30%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: auto;
    /* border: 1px solid rgb(70, 206, 16); */
}


/**************************/

.edit_delete-pendientes {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0.3rem;
    background-color: rgb(236, 216, 255);
    border: 1px solid rgb(162, 158, 202);
}

#px_h3_pax {
    padding: 0.6rem;
    /* border: 1px solid blue; */
}

#px_h3_pax label {
    font-size: 1.4rem;
    font-weight: 600;
}

#px_h3_calend {
    box-sizing: border-box;
    padding: 0.5rem;
    padding-left: 1rem;
    /* border: 1px solid blue; */
}

#lbl_datos_calend {
    font-size: 1rem;
    font-weight: 600;
}

/****************************/


.div-pendientes-textArea {
    box-sizing: border-box;
    width: 100%;
    padding: 0.4rem;
    margin-top: 0.4rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
    box-shadow: 2px 2px 3px 0;
}

#textArea-pendientes {
    box-sizing: border-box;
    width: 100%;
    padding: 0.4rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
}


.div-pendientes-edit-delete {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 0.2rem;
    margin-bottom: 0.4rem;
    /* border: 1px solid red; */
}

.div-pendientes-label {
    box-sizing: border-box;
    text-align: center;
    width: 100%;
    /* border: 1px solid red; */
}


.div-pendientes-edit-delete label {
    font-size: 0.8rem;
}

#id-hora-fecha-pendientes {
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    /* border: 1px solid rgb(136, 255, 0); */
}

#id-hora-fecha-pendientes p {
    padding-left: 0.6rem;
    text-align: left;
    font-size: 0.8rem;
}

#id-edit-delete-pendientes {
    box-sizing: border-box;
    width: 100%;
    text-align: right;
    /* border: 1px solid rgb(0, 102, 255); */
}

#img_edit-calendar,
#img_delete-calendar,
#img_psy-calendar,
#img_exped-calendar {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 1rem;
    cursor: pointer;
}


/********** ToolTip *************/

.div-pendientes-toolTip {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
}

#container-toolTip-save {
    box-sizing: border-box;
    width: 20%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;
    /* border: 1px solid rgb(0, 102, 255); */
}

.lbl-toolTip-save {
    visibility: hidden;
    box-sizing: border-box;
    position: absolute;
    font-size: 0.8rem;
    background-color: #5e5e5e;
    color: #fff;
    border-radius: 4px;
    padding: 0.2rem;
    z-index: 10;
    bottom: 100%;
    /* Posiciona el tooltip encima del elemento */
    transform: translateX(-20%);
    opacity: 0;
    transition: opacity 0.3s;
    /*border: 1px solid rgb(0, 255, 34); */
}


#container-toolTip-delete {
    box-sizing: border-box;
    width: 20%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;
    /* border: 1px solid rgb(0, 102, 255); */
}

.lbl-toolTip-delete {
    visibility: hidden;
    box-sizing: border-box;
    position: absolute;
    font-size: 0.8rem;
    background-color: #5e5e5e;
    color: #fff;
    border-radius: 4px;
    padding: 0.2rem;
    z-index: 10;
    bottom: 100%;
    /* Posiciona el tooltip encima del elemento */
    transform: translateX(-20%);
    opacity: 0;
    transition: opacity 0.3s;
}

/* regla CSS para mostrar el tooltip cuando se pasa 
el cursor sobre el elemento */

#container-toolTip-save:hover .lbl-toolTip-save {
    visibility: visible;
    opacity: 1;
}

#container-toolTip-delete:hover .lbl-toolTip-delete {
    visibility: visible;
    opacity: 1;
}


/*********************************/




/********* Tabla Px ***********/

#edit_delete-calendar {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: rgb(236, 216, 255);
}

/* div padre:
  hijo1: head-pax
  hijo2: sesion_exped
  */
.datos-px-encabezado {
    width: 100%;
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
    background-color: rgb(236, 216, 255);
}

/* div hijo 1 */
.head-pax {
    width: 40%;
    /* border: 1px solid blue; */
}

.head-pax p {
    text-align: left;
    font-size: 2rem;
    font-weight: 700;
    padding: 0.6rem;
    color: red;
}

/* div hijo 2 */
/*
    .sesion_exped {
        width: 60%;
        text-align: right;
        border: 1px solid rgb(98, 241, 42);
    }

    .sesion_exped label {
        margin-right: 1rem;
        cursor: pointer;
    }
*/

/* datos paciente con cita */
.estilo-datos-pax {
    width: 100%;
    /* border: 1px solid blue; */
    margin-bottom: 0.4rem;
}


/* Modal Px agendado */
/*div padre*/
.table-datos-modal {
    width: 100%;
    /* border: 1px solid #000; */
    text-align: left;
    border-collapse: collapse;
}

/* tabla Familiares */
.table-datos-familiar {
    width: 100%;
    /* border: 1px solid red; */
    text-align: left;
    border-collapse: collapse;
}



/*------------------*/
/*------------------*/
/*------------------*/
/*------------------*/
/*   Paciente agendado  por el Psicólogo  */
/*tabla: modal-pax-agendado */
/*------------------*/
/*------------------*/

.div-pax-agendado {
    width: 100%;
    border-radius: 0.3rem;
    border: 1px solid #696969;
}

.modal-pax-agendado {
    width: 100%;
}

.modal-pax-agendado label {
    float: left;
}

.table-modal-pax-agendado {
    width: 100%;
}

.datos-foto-modal {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    margin-bottom: 0.4rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
}

#img-agendado {
    box-sizing: border-box;
    width: 50%;
    margin: auto;
    /* border: 1px solid red; */
}

#img-agendado img {
    box-sizing: border-box;
    width: 5rem;
    height: 5rem;
    margin: auto;
    /* border: 1px solid red; */
}

#lbl-a-agendado {
    box-sizing: border-box;
    width: 50%;
    margin: auto;
    /* border: 1px solid red; */
}

#lbl-a-agendado label {
    font-size: 1rem;
    font-weight: 600;
}


.lbl-px-agendado,
.lbl-fecha-agendado,
.lbl-horaI-agendado,
.lbl-horaF-agendado,
.lbl-edad-agendado,
.lbl-trabajo-agendado,
.lbl-telefono-agendado,
.lbl-ciudad-agendado,
.lbl-psic-agendado {
    padding-left: 1rem;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.4rem;
    /* color:red; */
}

#lbl-px-agendado,
#lbl-fecha-agendado,
#lbl-horaI-agendado,
#lbl-horaF-agendado,
#lbl-edad-agendado,
#lbl-trabajo-agendado,
#lbl-telefono-agendado,
#lbl-ciudad-agendado,
#lbl-psic-agendado {
    padding-left: 1rem;
    font-size: 0.8rem;
}

#lbl-px-agendado {
    cursor: pointer;
}


/* ------ cita agendada por el Paciente ----- */

.div-citaAgendada-pac {
    display: flex;
    margin-bottom: 0.2rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
}

.div-citaAgendada2-pac {
    display: flex;
    margin-bottom: 0.2rem;
    /* border: 1px solid gray; */
}

.div-citaAgendada3-pac {
    width: 60%;
}

.div-citaAgendada4-pac {
    width: 30%;
}

.div-avatar-pac {
    width: 30%;
    /* border: 1px solid #6e4510; */
}

#img_Foto_px {
    box-sizing: border-box;
    width: 100%;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    border: 1px solid gray;
    box-shadow: 4px 4px 6px 0;
}

.div-fotoPac-pac {
    width: 70%;
    flex-direction: column;
    /* border: 1px solid #36b305; */
}

/*------------------*/
/*------------------*/
/*------------------*/




