/* ---- ADD PACIENTES EXPRESS --------*/

/* -------- Div principales -------- */

.div-datos-express {
    box-sizing: border-box;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
}

.px_div_2_express {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    /* border: 1px solid rgb(12, 22, 161); */
}

.div_enSesion_express {
    box-sizing: border-box;
    width: 15%;
    /* border: 1px solid green; */
}

#lbl_tipoUsuario_express {
    font-size: 1rem;
    font-weight: 600;
}

#lbl_enSesion_express {
    font-size: 1rem;
    font-weight: 400;
}


.div_titulo_express {
    box-sizing: border-box;
    width: 70%;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    /* border: 1px solid rgb(128, 36, 0); */
}



/*********************/


.px_div_3_express {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    /* border: 1px solid rgb(12, 22, 161); */
}

.px_div_4_express {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 2rem;
    /* border: 1px solid rgb(12, 22, 161); */
}

.px_div_5_express {
    box-sizing: border-box;
    width: 60%;
    margin: auto;
    padding: 0.6rem;
    display: flex;
    border-radius: 0.3rem;
    box-shadow: 2px 2px 3px 0;
    border: 1px solid gray;
}

.px_div_6_express {
    box-sizing: border-box;
    width: 40%;
    /* border: 1px solid rgb(12, 22, 161); */
}

.px_div_6_express label {
    font-size: 0.8rem;
    font-weight: 600;
}

.px_div_7_express {
    box-sizing: border-box;
    width: 60%;
    /* border: 1px solid rgb(12, 22, 161); */
}

#lbl-autogen-express {
    font-size: 0.8rem;
    font-weight: 600;
    /* border: 1px solid green; */
}


/******* TABLA DE DATOS *******/


.div-table-express {
    box-sizing: border-box;
    width: 60%;
    margin: auto;
    padding: 0.6rem;
    margin-bottom: 0.6rem;
    border-radius: 0.3rem;
    box-shadow: 2px 2px 3px 0;
    border: 1px solid gray;
}


/* medida de la tabla */
.table-express {
    box-sizing: border-box;
    width: 100%;
    margin: auto;
    border-collapse: separate;
    border-spacing: 5px 10px;
    /* border: 1px solid #006809; */
}

.express-px-tr {
    padding: 0.6rem;
    border-radius: 0.3rem;
    background-color: rgb(236, 216, 255);
}

.express-px-colum label {
    box-sizing: border-box;
    width: 100%;
    font-size: 0.8rem;
    padding-left: 0.8rem;
}


/*------ columna input --------*/

.express-px-colum input {
    box-sizing: border-box;
    width: 100%;
    font-size: 0.8rem;
    padding: 0.8rem;
    border: 1px;
    outline: none;
    background: transparent;
    border-bottom: 1px solid #797979;
}

/*  ---------------  */


/* ---- TABLA AUTOREGISTRO ---- */

/*   div Padre   autoreg y botones   */

.div-autoregistro-botones-express {
    box-sizing: border-box;
    width: 60%;
    margin: auto;
    padding: 0.6rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-radius: 0.3rem;
    box-shadow: 2px 2px 3px 0;
    border: 1px solid gray;
}


.table-nomUsu-contrasena {
    width: 100%;
    margin: auto;
    /* border: 1px solid red; */
}


/*  columnas Tabla autoregistro  */

.express-px-generar {
    width: 33%;
    text-align: left;
    padding: 0.5rem;
    background-color: rgb(216, 216, 255);
}

.express-px-generar-izq {
    width: 33%;
    text-align: left;
    padding: 0.5rem;
}

.express-px-generar-izq input {
    width: 100%;
    height: 1.6rem;
    font-size: 0.8rem;
    padding-left: 15px;
    border: 1px;
    outline: none;
    border-bottom: 1px solid #797979;
}



/* Div botones: ENVIAR CANCELAR*/

.div-expressPx-botones {
    box-sizing: border-box;
    margin-top: 3rem;
    /* border: 1px solid #ff0000; */
}

.div-express-botones {
    box-sizing: border-box;
    width: 40%;
    margin: auto;
    display: flex;
    /* border: 1px solid #00ff15; */
}

.div-express-enviar {
    box-sizing: border-box;
    width: 20%;
    margin: auto;
    /* border: 1px solid rgb(5, 101, 165); */
}

.div-express-cancelar {
    box-sizing: border-box;
    width: 20%;
    margin: auto;
    /* border: 1px solid rgb(5, 101, 165); */
}

#btn-enviar-expressPx {
    border-radius: 0.3rem;
    border: 1px solid gray;
}

#btn-cancelar-expressPx {
    border-radius: 0.3rem;
    border: 1px solid gray;
}

/*----------------------------------------*/


#select-sexo-express,
#select-edo-express {
    width: 100%;
    height: 1.6rem;
    font-size: 0.8rem;
    outline: none;
    color: #494949;
}