/* ---- ADD FAMILIAR --------*/
/* ----  ------------ --------*/


.div-1-addFam {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin: auto;
  /* border: 1px solid red; */
}

.div_2_addFam {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(12, 22, 161); */
}

/* .px_sesion_addFam {
  border: 1px solid rgb(12, 22, 161);
} */

#lbl-enSesion-addFam {
  font-weight: 600;
}

#lbl-enSesion_addFam label {
  font-size: 0.8rem;
  font-weight: 400;
}

.div_lbl_addFam {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  /* border: 1px solid rgb(0, 23, 128); */
}

.div_lbl_addFam label {
  font-size: 2rem;
  font-weight: 600;
}

/**************************/

/***** Div Datos Paciente *******/

.div-datos-addFam {
  box-sizing: border-box;
  width: 80%;
  margin: auto;
  margin-bottom: 3rem;
  /* border: 1px solid rgb(0, 23, 128); */
}

/*--------------------*/


/**************************/
.div-selectPacRel-fam {
  box-sizing: border-box;
  width: 60%;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin: auto;
  /* border: 1px solid rgb(0, 89, 255); */
}

.selectPacRel-fam {
  width: 100%;
  margin-right: 1rem;
  /* border: 1px solid rgb(4, 136, 10); */
}

.selectPacFam-filtro {
  width: 100%;
  display: flex;
  margin-top: 1rem;
  /* border: 1px solid rgb(3, 88, 114); */
}

.selectPacFam {
  width: 60%;
}

.selectFiltro {
  width: 40%;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid rgb(83, 15, 209); */
}

/*------- DISPLAY pac relacionados ---------*/

.displayPacRelacionados {
  width: 100%;
  margin: auto;
  /* border: 1px solid rgb(180, 6, 137); */
}

.displayPacRelacionados_a {
  width: 60%;
  margin: auto;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(180, 6, 137); */
}

.displayPacRelacionados_a label {
  font-size: 1.2rem;
  font-weight: 450;
  margin-bottom: 1rem;
}

.tablePacRelacionados_a {
  width: 80%;
  /* border: 1px solid rgb(23, 6, 180); */
}

#encabezadoPacRel {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-weight: 600;
  padding: 1rem;
  background-color: rgb(203, 185, 247);
}

#encabezadoPacRel th:first-child {
  width: 20%;
  text-align: left;
  font-size: 0.8rem;
}

#encabezadoPacRel th:nth-child(2) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}

#encabezadoPacRel th:nth-child(3) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}

/*---------------------------------*/

#displayPacRel {
  width: 100%;
  /* border: 1px solid rgb(6, 75, 3); */
}

#displayPacRel td:first-child {
  width: 20%;
  text-align: left;
  padding-top: 0.6rem;
  font-size: 0.8rem;
}

#displayPacRel td:nth-child(2) {
  width: 25%;
  text-align: center;
  padding-top: 0.6rem;
  font-size: 0.8rem;
}

#displayPacRel td:nth-child(3) {
  width: 15%;
  font-size: 0.8rem;
  padding-top: 0.6rem;
  text-align: center;
}

/*******************************/

/* ¿El familiar que se ingresará... */

.radio_btn_esPac {
  width: 60%;
  flex-direction: column;
  margin: auto;
  /* border: 1px solid rgb(6, 75, 3); */
}

/* #radio_label_esPac {
  border: 1px solid rgb(65, 52, 18);
} */

#radio_label_esPac label {
  font-size: 1.2rem;
  font-weight: 450;
  margin-bottom: 1rem;
}

#radio_input_esPac {
  display: flex;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(18, 77, 72); */
}

#input_esPac {
  width: 10%;
  margin-right: 0.6rem;
  /* border: 1px solid rgb(219, 33, 33); */
}

#input_esPac label {
  margin-left: 0.2rem;
}


/********* si No Es Pac **********************/

.div-noEsPac-addFam {
  width: 60%;
  margin: auto;
  /* border: 1px solid rgb(219, 33, 33); */
}

.div-noEsPac2-addFam {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(219, 33, 33); */
}

.div-noEsPac_a-addFam {
  box-sizing: border-box;
  width: 70%;
}

.div-noEsPac_b-addFam {
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 30%;
}

/********** BUSCADOR ***********/
.div_buscadorFam_addfam {
  width: 50%;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(14, 131, 3); */
}


/* ---  Ingresa los datos del familiar  --- */

/* .div_selectParent_addfam {
  border: 1px solid rgb(14, 131, 3);
} */

.div_selectParent_addfam label {
  font-size: 1rem;
  margin-right: 1rem;
}


.div-ingresarDatosFam {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  border: 1px solid rgb(4, 94, 82);
}

.div-ingresarDatosFam label {
  padding: 0.6rem;
  font-size: 1rem;
}



/*  -------   Cargar Foto  -------   */
.div_content_foto_addFam {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  margin: auto;
  border-radius: 0.3rem;
  padding: 0.6rem;
  margin-top: 1rem;
  background-color: rgb(255, 220, 176);
  border: 1px solid gray;
}

.div-foto-addFam {
  box-sizing: border-box;
  text-align: center;
  width: 40%;
  height: 100%;
  /* border: 1px solid red; */
}

.div-foto-addFam img {
  box-sizing: border-box;
  width: 95%;
  border-radius: 0.2rem;
  box-shadow: 4px 4px 6px 0 #494949;
  /* border: 1px solid red; */
}



/* 2do select, buscar familiar*/
#div_image_previa_addFam {
  box-sizing: border-box;
  width: 50%;
  display: flex;
  /* border: 1px solid red; */
}

#div_image_previa_addFam img {
  box-sizing: border-box;
  width: 60%;
  margin: auto;
  border-radius: 0.2rem;
  box-shadow: 4px 4px 6px 0 #494949;
  /* border: 1px solid red; */
}

#div_lbl_image_addFam {
  box-sizing: border-box;
  width: 50%;
  margin: auto;
  text-align: center;
  font-weight: 600;
  /* border: 1px solid red; */
}

/*--------------------------*/


.div_btn_foto_addFam {
  box-sizing: border-box;
  padding: 1rem;
  width: 60%;
  display: flex;
  align-items: end;
  justify-content: left;
  /* border: 1px solid rgb(0, 89, 255); */
}

.div_btn_foto_addFam input {
  font-size: 1rem;
  /* border: 1px solid rgb(0, 89, 255); */
}


/******* Table AddFamiliar *************/

.div-table-addFam {
  box-sizing: border-box;
  width: 100%;
  margin-top: 0.6rem;
  padding: 0.6rem;
  border-radius: 0.3rem;
  background-color: rgb(255, 220, 176);
  border: 1px solid gray;
}


#table-addFam {
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px 5px;
  margin-top: 1rem;
  /* border: 1px solid rgb(0, 89, 255); */
}


/******   TD -np   no pac ********/

.addFam-td-izq-np {
  box-sizing: border-box;
  width: 30%;
  background-color: rgb(255, 221, 180);
}

.addFam-td-der1-np {
  box-sizing: border-box;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}

.addFam-td-der2-np {
  box-sizing: border-box;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}

.addFam-td-der3-np {
  box-sizing: border-box;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}

.addFam-td-der1-a-np input {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid gray;
  border-radius: 0.3rem;
  padding: 0.2rem;
  font-size: 0.8rem;
  padding-left: 0.4rem;
}

/****** TD select *******/

.addFam-td-select {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  border-radius: 0.3rem;
}

/***** INPUT ******/

.addFam-td-der1-np input {
  box-sizing: border-box;
  border: 1px solid gray;
  /* outline: none; */
  border-radius: 0.3rem;
  padding: 0.2rem;
  font-size: 0.8rem;
  padding-left: 0.4rem;
  /* background-color: transparent; */
}

.addFam-td-der2-np input {
  box-sizing: border-box;
  border: 1px solid gray;
  /* outline: none; */
  border-radius: 0.3rem;
  padding: 0.2rem;
  font-size: 0.8rem;
  padding-left: 0.4rem;
  /* background-color: transparent; */
}

.addFam-td-der3-np input {
  box-sizing: border-box;
  border: 1px solid gray;
  /* outline: none; */
  border-radius: 0.3rem;
  padding: 0.2rem;
  font-size: 0.8rem;
  padding-left: 0.4rem;
  /* background-color: transparent; */
}




/********* RADIO BUTTOM sexo **********/

.radio-container label {
  font-size: 0.8rem;
  color: #494949;
  margin-right: 0.6rem;
  margin-left: 0.4rem;
}


/************************/

/*  ID Select */

#select-paciente-addFam {
  width: 100%;
  font-size: 0.8rem;
  padding: 0.2rem;
  outline: none;
  color: #494949;
  border-radius: 0.3rem;
}

#select-parentescoDelFam {
  width: 50%;
  height: 2rem;
  font-size: 0.8rem;
  padding: 0.2rem;
  outline: none;
  color: #494949;
  border-radius: 0.3rem;
  margin-top: 0.4rem;
}

#select-parentescoDelPac {
  width: 50%;
  height: 2rem;
  font-size: 0.8rem;
  padding: 0.2rem;
  outline: none;
  color: #494949;
  border-radius: 0.3rem;
  margin-top: 0.4rem;
}

#select-sexo-addFam,
#select-parentesco-addFam,
#select-edo-civil-addFam,
#select-edo-addFam,
#select-pais-addFam {
  width: 100%;
  font-size: 0.8rem;
  padding: 0.2rem;
  outline: none;
  color: #494949;
}


#select-paciente-addFam option {
  font-size: 0.8rem;
  color: #000000;
}

#select-parentescoDelFam option {
  font-size: 0.8rem;
  color: #000000;
}

#select-parentescoDelPac option {
  font-size: 0.8rem;
  color: #000000;
}

#select-sexo-addFam option,
#select-parentesco-addFam option,
#select-edo-civil-addFam option,
#select-edo-addFam option,
#select-pais-addFam option {
  font-size: 0.8rem;
  color: #000000;
}

/*--------------------------------------*/



/*------------- SÍ ES PACIENTE -----------*/

.div-siEsPac {
  width: 100%;
  margin: auto;
  /* border: 1px solid red; */
}

.div-siEsPac2 {
  width: 60%;
  margin: auto;
  flex-direction: column;
  /* border: 1px solid red; */
}

.div-siEsPac3 {
  width: 100%;
  /* border: 1px solid rgb(0, 47, 255); */
}

.div-siEsPac4 {
  width: 100%;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(3, 109, 3); */
}

.div-siEsPac5 {
  box-sizing: border-box;
  margin-right: 1rem;
  width: 70%;
}

.div-siEsPac6 {
  box-sizing: border-box;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: left;
}

/*----- Seleccionar el parentesco -----*/
.div-select-paren-sf {
  width: 60%;
  margin: auto;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(3, 109, 3); */
}

.div-select-paren-sf label {
  font-size: 1rem;
  font-weight: 600;
  margin-right: 1rem;
}



/*  ------- Foto / no Foto  ------- */
.div-avatar-addF {
  box-sizing: border-box;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin: auto;
  /* border: 1px solid red; */
}

.avatar-addF {
  width: 40%;
  /* border: 1px solid rgb(4, 117, 4); */
}

.avatar-addF2 {
  width: 60%;
  /* border: 1px solid rgb(4, 117, 4); */
}

.avatar-addF2 label {
  margin-right: 1rem;
  font-weight: 600;
  font-size: 0.8rem;
}

/* -------- Tabla Es Pac ------- */

.div-table-EsPac-addFam {
  width: 60%;
  margin: auto;
  border-radius: 0.3rem;
  margin-top: 1rem;
  border: 1px solid gray;
}

#table-EsPac-addFam {
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px 5px;
  /* border: 1px solid rgb(0, 89, 255); */
}


/******   TD   Es pac ********/

.addFam-td-izq-ep {
  box-sizing: border-box;
  width: 30%;
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.8rem;
  background-color: rgb(255, 221, 180);
}

.addFam-td-der1-ep {
  box-sizing: border-box;
  font-size: 0.8rem;
  width: 60%;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}



/*---------------------------*/
/* Div botones: ENVIAR CANCELAR*/

/*   div Padre   autoreg y botones   */

.div-autoregistro-botones-addFam {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 2rem;
  /* border: 1px solid blue; */
}

.div-btn-alineacion-addFam {
  box-sizing: border-box;
  margin: auto;
  width: 50%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
  background-color: rgb(255, 221, 180);
  border: 1px solid gray;
}


/* Div botones: ENVIAR CANCELAR*/
.div-addFam-enviar {
  box-sizing: border-box;
  width: 20%;
  /* border: 1px solid rgb(5, 101, 165); */
}

.div-addFam-cancelar {
  box-sizing: border-box;
  width: 20%;
  /* border: 1px solid rgb(5, 101, 165); */
}


#btn-enviar-addFam,
#btn-cancelar-addFam {
  border-radius: 0.4rem;
  border: 1px solid gray;
  cursor: pointer;
}

/*----------------------------------------*/