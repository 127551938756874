

/* ---- ADD PSICÓLOGO --------*/
/* ----  ------------ --------*/


.div-1-addPsi {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}

.div_2_addPsi {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(12, 22, 161); */
}

#lbl-enSesion_addPsi {
  font-weight: 600;
}

#lbl-enSesion_addPsi label {
  font-size: 0.8rem;
  font-weight: 400;
}

.div_lbl_addPsi {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  /* border: 1px solid rgb(0, 23, 128); */
}

.div_lbl_addPsi label {
  font-size: 2rem;
  font-weight: 600;
}


/***** Div Datos Colega *******/

.div-datos-addPsi {
  box-sizing: border-box;
  width: 50%;
  margin: auto;
  margin-bottom: 3rem;
  /* border: 1px solid rgb(0, 23, 128); */
}

.div_content_foto_addPsi {
  box-sizing: border-box;
  width: 100%;
  height: 30%;
  display: flex;
  /* border: 1px solid rgb(51, 255, 0); */
}

.div-foto-px-addPsi {
  box-sizing: border-box;
  text-align: center;
  width: 30%;
  height: 100%;
  /* border: 1px solid red; */
}

.div_btn_foto_addPsi {
  box-sizing: border-box;
  padding: 1rem;
  width: 70%;
  display: flex;
  align-items: end;
  justify-content: left;
  /* border: 1px solid rgb(0, 89, 255); */
}


/****** TABLA DATOS *******/

.div_tabla_addPsi {
  box-sizing: border-box;
  /* border: 1px solid #002168; */
}

.table-addPsi {
  box-sizing: border-box;
  width: 100%;
  border-collapse: separate;
  border-spacing: 5px 10px;
  /* border: 1px solid #006809; */
}


/****** TR ********/

/****** TD ********/

.addPsi-td-izq {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  background-color: rgb(255, 221, 180);
}

.addPsi-td-der1 {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}

.addPsi-td-der2 {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}

.addPsi-td-der3 {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}

/****** TD select *******/

.addPsi-td-select {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
}

/***** INPUT ******/

.addPsi-td-der1 input {
  box-sizing: border-box;
  border: 1px solid gray;
  /* outline: none; */
  border-radius: 0.3rem;
  padding: 0.2rem;
  font-size: 0.8rem;
  padding-left: 0.4rem;
  /* background-color: transparent; */
}

.addPsi-td-der2 input {
  box-sizing: border-box;
  border: 1px solid gray;
  /* outline: none; */
  border-radius: 0.3rem;
  padding: 0.2rem;
  font-size: 0.8rem;
  padding-left: 0.4rem;
  /* background-color: transparent; */
}

.addPsi-td-der3 input {
  box-sizing: border-box;
  border: 1px solid gray;
  /* outline: none; */
  border-radius: 0.3rem;
  padding: 0.2rem;
  font-size: 0.8rem;
  padding-left: 0.4rem;
  /* background-color: transparent; */
}


/********* RADIO BUTTOM **********/

.radio-container label {
  font-size: 0.8rem;
  color: #494949;
  margin-right: 0.6rem;
}


/************************/

/*  ID Select */

#select-sexo-addPsi,
#select-escolaridad-addPsi,
#select-pref_sex-addPsi,
#select-titulo-addPsi,
#select-edo-civil-addPsi,
#select-edo-addPsi,
#select-pais-addPsi {
  width: 100%;
  font-size: 0.8rem;
  padding: 0.2rem;
  outline: none;
  color: #494949;
}


#select-sexo-addPsi option,
#select-escolaridad-addPsi option,
#select-pref_sex-addPsi option,
#select-titulo-addPsi option,
#select-edo-civil-addPsi option,
#select-edo-addPsi option,
#select-pais-addPsi option {
  font-size: 0.8rem;
  color: #000000;
}

/* ----------------- */


/*---------------------------*/
/* Div botones: ENVIAR CANCELAR*/

/*   div Padre   autoreg y botones   */

.div-autoregistro-botones-addPsi {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 2rem;
  /* border: 1px solid blue; */
}

.div-btn-alineacion-addPsi {
  box-sizing: border-box;
  margin: auto;
  width: 20%;
  display: flex;
  /* border: 1px solid blue; */
}


/* Div botones: ENVIAR CANCELAR*/
.div-addPsi-enviar {
  box-sizing: border-box;
  width: 50%;
  margin: auto;
  /* border: 1px solid rgb(5, 101, 165); */
}

.div-addPsi-cancelar {
  box-sizing: border-box;
  width: 50%;
  margin: auto;
  /* border: 1px solid rgb(5, 101, 165); */
}


#btn-enviar-addPsi,
#btn-cancelar-addPsi {
  border-radius: 0.4rem;
  border: 1px solid gray;
  cursor: pointer;
}

/*----------------------------------------*/



