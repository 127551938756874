

/*--------- Content Login ------------------*/

/*1vh = 1% de la altura del viewport*/



/*    Está en el index.css  
.content-login {
    
  }
*/


.img-fondo {
    box-sizing: border-box;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 85vh;
    display: flex;
    justify-content: center;
    background-image: url("../img/fondo-img-1.png");
    background-position: center center;
    background-attachment: fixed;
    background-size: cover; /*cubrir el fondo con imagen */
    /* border: 1px solid red; */
}

/* lado izquierdo */

.frases-login {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    padding: 2rem;
    /* border: 1px solid red; */
}

#p_1 {
    margin-top: 2rem;
    font-size: 3rem;
    color: #00330b;
}

#p_2 {
    margin-top: 1rem;
    font-size: 1.4rem;
}

#p_3 {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: end;
    color: rgb(0, 58, 35);
}



/* ------- CAJA LOGIN -------- */

.div-caja-login {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    /*border: 1px solid black;*/
}

.wrapper_login{
    width: 75%;
    height: 50%;
    margin: auto;
    background: rgba(255, 255, 255, .5);
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(20px);
    box-shadow: 0 0 10px rgba(0, 0, 0, .4);
    border-radius: 4px;
    padding: 1rem;
}



/* div  -- Títuolo: Iniciar Sesión */

.titulo-caja-login {
    /* border: 1px solid rgb(221, 8, 44); */
    width: 100%;
    height: 20%;
    text-align: center;
}

.titulo-caja-login p {
    font-size: 1.4rem;
    color: rgb(88, 88, 88);
    font-weight: 600;
}



/* Input correo, contraseña */

.input-box {
    box-sizing: border-box;
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid rgb(5, 104, 170); */
}

.input-box input {
    box-sizing: border-box;
    width: 100%;
    height: 2rem;
    padding-left: 0.8rem;
    background: transparent;
    border-radius: 5px;
    outline: none;
    font-size: 0.8rem;
    color: rgb(65, 65, 65);
    border: 1px solid rgb(134, 134, 134);
}

#id-correo-login {
    margin-bottom: 0.4rem;
}


/* check box, Recordar contraseña*/

.recordar-olvidar-pass {
    /* border: 1px solid rgb(0, 255, 42); */
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

/*---------*/
.check_box {
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    /* border: 1px solid rgb(5, 83, 5); */
}

.check_box label {
    box-sizing: border-box;
    padding-left: 1rem;
    font-size: 0.8rem;
    color: rgb(65, 65, 65);
}

/*------------*/
.olvidar_pass {
    /* border: 1px solid rgb(5, 17, 83); */
    width: 100%;
    text-align: right;
}

.olvidar_pass a{
    color: #303030;
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: 600;
}

.olvidar_pass a:hover{
    text-decoration: underline;
}


/* div -- botones: ingresar, salir*/

.botones_login {
    /* border: 1px solid red; */
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


#btn_entrar {
    font-size: 0.8rem;
    margin-right: 1rem;
    width: 4rem;
    height: 1.4rem;
    border: none;
    border-radius: 5px;
    background: #dddddd;
    cursor: pointer;
    color: rgb(65, 65, 65);
    font-weight: 600;
}

#btn_entrar:hover {
    transform: scale(1.05);
    background: #bdbdbd;
}

#btn_salir {
    font-size: 0.8rem;
    width: 4rem;
    height: 1.4rem;
    border: none;
    border-radius: 5px;
    background: #dddddd;
    cursor: pointer;
    color: rgb(65, 65, 65);
    font-weight: 600;
}

#btn_salir:hover {
    transform: scale(1.05);
    background: #bdbdbd;
}

/*  div -- No tengo cuenta */

.register-link {
    /* border: 1px solid blue; */
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    padding-top: 1rem;
}

.register-link label {
    font-size: 0.8rem;
    color: rgb(65, 65, 65);
    margin-right: 0.8rem;
}

.register-link a {
    font-size: 0.8rem;
    color: #303030;
    text-decoration: none;
    font-weight: 600;
}

.register-link a:hover{
    text-decoration: underline;
}



