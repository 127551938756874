/*    REPORTES     */


.div-1-reporte {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}


.px_div_2_reporte {
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(161, 12, 141); */
}

.px_sesion_reporte {
  box-sizing: border-box;
  width: 20%;
  /* border: 1px solid blue; */
}

.div_lbl_reporte {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  /* border: 1px solid rgb(0, 195, 255); */
}

.div_lbl_reporte label {
  font-size: 1.6rem;
  font-weight: 600;
}

/*------------------*/



/*------- Contenedor cards -----------*/

.px_div_3_reporte {
  box-sizing: border-box;
  width: 60%;
  margin: auto;
  /* border: 1px solid rgb(161, 12, 141); */
}


/********** CARDS ************/

.div-3-card-reporte {
  box-sizing: border-box;
  flex-direction: column;
  align-items: start;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(6, 116, 34); */
}

.card-body label {
  font-size: 1.2rem;
  font-weight: 600;
}


/*     foto y tabla 1      */

#div_headPac_1_reporte {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid gray; */
}

#div_photo_reporte {
  box-sizing: border-box;
  width: 30%;
  text-align: center;
  padding: 0.6rem;
  margin-right: 1rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
}

#div_photo_reporte img {
  box-sizing: border-box;
  width: 8rem;
  height: 8rem;
  box-shadow: 4px 4px 6px 0;
  /* border: 1px solid rgb(45, 6, 116); */
}

#div_headPac_2_reporte {
  box-sizing: border-box;
  width: 70%;
  padding: 0.6rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
}


/* ------ tabla 1 ------- */

#table_headPac_1_reporte {
  box-sizing: border-box;
  width: 100%;
  /* border: 1px solid rgb(116, 6, 6); */
}

#tr_headPac_reporte {
  box-sizing: border-box;
  width: 100%;
  /* border: 1px solid rgb(116, 6, 6); */
}

#th_nom_reporte {
  box-sizing: border-box;
  width: 40%;
  font-weight: 600;
  padding: 0.6rem 0 0.6rem 0;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_edad_reporte {
  box-sizing: border-box;
  width: 15%;
  font-weight: 600;
  padding: 0.6rem 0 0.6rem 0;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_sexo_reporte {
  box-sizing: border-box;
  width: 20%;
  font-weight: 600;
  padding: 0.6rem 0 0.6rem 0;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_edoCivil_reporte {
  box-sizing: border-box;
  width: 20%;
  font-weight: 600;
  padding: 0.6rem 0 0.6rem 0;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_perent_reporte {
  box-sizing: border-box;
  width: 30%;
  font-weight: 600;
  padding: 0.6rem 0 0.6rem 0;
  /* border: 1px solid rgb(12, 15, 161); */
}



/* tabla 2 */

#table_headPac_2_reporte {
  box-sizing: border-box;
  width: 100%;
  /* border: 1px solid rgb(116, 6, 6); */
}

#th_dom_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 40%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_ciudad_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 30%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_cp_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 15%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}


/* tabla 3 ---- tel y correo */

#table_headPac_3_reporte {
  box-sizing: border-box;
  width: 100%;
  /* border: 1px solid rgb(116, 6, 6); */
}

#th_tel_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 20%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_correo_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 40%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}



/******* card 2 **** Info Paciente  ******/

.div-3-card2-reporte {
  box-sizing: border-box;
  flex-direction: column;
  align-items: start;
  /* border: 1px solid rgb(6, 116, 34); */
}

#tr_infoPac_reporte {
  box-sizing: border-box;
  width: 100%;
  /* border: 1px solid rgb(116, 6, 6); */
}

#th_esc_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 20%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_prof_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 20%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_trabajo_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 20%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_canalizar_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 20%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}


/* ------ Citas ------- */

#th_noCita_cita {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 20%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_fecha_cita {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 20%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_hora_cita {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 20%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_estatus_cita {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 20%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

/* ------ Sesiones ------- */

.div-3-cardSesiones-reporte {
  margin-bottom: 1rem;
  /* border: 1px solid rgb(6, 116, 34); */
}

#tr_sesionPac_reporte {
  box-sizing: border-box;
  width: 100%;
  /* border: 1px solid rgb(116, 6, 6); */
}

#th_noSesion_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 20%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_fechaSe_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 20%;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_horaSe_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 20%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_estatusSe_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 20%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#td_sesionPac_reporte {
  box-sizing: border-box;
  width: 90%;
  /* border: 1px solid rgb(12, 15, 161); */
}


/* ------ Canalizaciones ------- */

#th_Colega_can {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 20%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_Especialidad_can {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 20%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_fechaIn_can {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 20%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_fechaOut_can {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  width: 20%;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}


/* -------- expediente --------- */

#th_pruebas_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_sugerencias_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_hisMedic_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_expSig_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_familiograma_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}

#th_familiograma_reporte {
  box-sizing: border-box;
  padding: 0.6rem 0 0.6rem 0;
  font-weight: 600;
  /* border: 1px solid rgb(12, 15, 161); */
}


.div-btnSalir1-reporte {
  width: 100%;
  margin-top: 1rem;
}

.div-btnSalir2-reporte {
  width: 80%;
  margin: auto;
  text-align: center;
  margin-top: 1rem;
  padding: 0.6rem;
}



