


/*------------------*/
/* ---------- AGENDAR CITA -------- */
/*------------------*/
/*------------------*/

.div-agendar-cita {
    width: 100%;
    /* border: 1px solid #f10c0c; */
}

.table-registro-cita {
    width: 100%;
    /* border: 1px solid #696969; */
}

/* label izquierda modal*/
.registrar-cita-td-izq {
    width: 35%;
    /* background-color: aqua; */
}

/* label derecha modal*/
.registrar-cita-td-der {
    width: 60%;
    /* background-color: rgb(0, 119, 255); */
}

.lbl-paciente-registro,
.lbl-fecha-registro,
.lbl-horaI-registro,
.lbl-horaF-registro,
.lbl-comentario-registro {
    font-size: 0.8rem;
    float: left;
    padding: 0.6rem;
    font-weight: 600;
}

#input-fecha,
#input-horaI,
#input-horaF,
#input-comentario {
    width: 100%;
    font-size: 0.8rem;
    border: none;
    outline: none;
    background-color: transparent;
}

/*----------------------*/


/* ------- SELECT pax ------- */

#select-paciente option {
    padding: 0.8rem;
    font-size: 0.8rem;
}

#select-psicologo option {
    padding: 0.8rem;
    font-size: 0.8rem;
}




/*Select option*/
.selected-pax {
    width: 100%;
    /* padding: 8px 10px 8px 10px; */
    font-size: 0.8rem;
    position: relative;
    /* border-radius: 0.25rem; */
    border: none;
    outline: none;
}

.selected-pax::before,
.selected-pax::after {
    --size: 0.3rem;
    content: "";
    position: absolute;
    right: 1rem;
    pointer-events: none;
}

.selected-pax::before {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-bottom: var(--size) solid black;
    top: 40%;
}

.selected-pax::after {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-top: var(--size) solid black;
    top: 55%;
}



/* Efecto fila sombreada Tabla px */

.padding-row:nth-child(even) {
    background-color: #d6d6d6;
}

/*
 .padding-row:hover {
    background-color: #a0d3c8;
 }
*/
/*--------------------------------*/


/* Label sin datos de tabla */

.label-selec-px {
    margin-top: 3rem;
    text-align: center;
    font-size: 0.8rem;
}







