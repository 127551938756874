/*https://www.tosch.dev/blog/hamburger-menu-css*/


#div-menuHamb {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: end;
  /* border: 1px solid red; */
}

.nav-menuHamb {
  width: 100%;
  text-align: right;
  margin-top: 0.4rem;
}

/*   Efecto Responsive   */

#ul-menuHamb {
  display: none;
}

#in-menuHamb:checked~ul {
  display: block;
}

#in-menuHamb {
  display: none;
}

/*-------------------*/

.nav-menuHamb label {
  box-sizing: border-box;
  display: inline-block;
  border-radius: 3px;
  margin-right: 0.4rem;
  padding: 0 0.2rem 0 0.2rem;
  font-size: 1.2rem;
  text-align: center;
  font-weight: bold;
  user-select: none;
}

/* Estilo del boton cuando se pasa 
  el mouse por encima*/
.nav-menuHamb label:hover {
  background: grey;
}

/* Estilo del boton cuando el menú está 
  expandido*/
.nav-menuHamb input:checked~label {
  background: lightgrey;
}

.nav-menuHamb ul {
  margin-top: 0;
  padding: 0;
}


#li-menuHamb {
  display: block;
  background: lightgrey;
  padding: 0.4rem;
  list-style: none;
  border-bottom: 1px solid grey;
  font-size: 0.8rem;
}

#li-menuHamb a {
  text-decoration: none;
  color: rgb(80, 80, 80);
}

/*Estilo cuando el mouse pasa encima 
  de cada link del menu*/
#li-menuHamb:hover {
  filter: brightness(110%);
}