.div-1-pend {
    box-sizing: border-box;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    /* overflow: auto;
    overflow-y: auto; */
    /* border: 1px solid red; */
    background-color: bisque;
}


.px_div_2_pend {
    box-sizing: border-box;
    width: 100%;
    height: 15%;
    /* border: 1px solid rgb(161, 12, 141); */
}

.px_h1_pend {
    box-sizing: border-box;
    width: 100%;
    height: 60%;
    margin-bottom: 2rem;
    /* border: 1px solid green; */
}


.px_h1_pend h1 {
    text-align: center;
    margin: auto;
    font-size: 2rem;
}

.div-3-expediente-pend {
    box-sizing: border-box;
    margin: auto;
    width: 60%;
    height: 100%;
    padding: 0.4rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.3rem;
    border: 1px solid gray;
    box-shadow: 2px 2px 3px 0;
    /* background-color: aqua; */
}

.div-selec-px-pend {
    width: 100%;
    display: flex;
    justify-content: center;
}

#label-selec-px-pend {
    font-size: 0.8rem;
}


/******** Botón AGREGAR pendiente ********/

.div-btn-addPx-pend {
    box-sizing: border-box;
    width: 60%;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: end;
    /* border: 1px solid rgb(243, 48, 210); */
}

.div-btn-addPx-pend button {
    box-sizing: border-box;
    width: 20%;
    padding: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    border: none;
    border-radius: 5px;
    color: aliceblue;
    cursor: pointer;
    background-color: blueviolet;
}

.div-btn-addPx-pend button:hover {
    transform: scale(1.05);
    background-color: rgb(121, 0, 235);
}

.div-btn-addPx-pend button img {
    box-sizing: border-box;
    height: 75%;
    margin-right: 0.2rem;
    /* border: 1px solid aliceblue; */
}

.div-btn-addPx-pend button label {
    box-sizing: border-box;
    /* border: 1px solid aliceblue; */
}


/*-----------------------*/
/* menu desplegable flecha */
/*-----------------------*/
/* https://www.silocreativo.com/details-summary-elementos-desplegables-ocultos-html/ */


.faq-container-pend {
    box-sizing: border-box;
    width: 100%;
    /* height: 100%; */
    margin-top: 0.4rem;
    /* overflow: auto;
    overflow-y: auto; */
    /* border: 1px solid red; */
}

details {
    box-sizing: border-box;
    background-color: #f6f8fa;
    width: 100%;
    margin-bottom: 0.6rem;
    border-radius: 5px;
    border: 1px solid #d8e0e9;
    color: #364f6b;
    position: relative;
}

details summary {
    box-sizing: border-box;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0.6rem;
    cursor: pointer;
    list-style: none;
}

details p {
    padding: 0.6rem;
    margin: 0 0.6rem 0.6rem 0.6rem;
    text-align: left;
    font-size: 0.8rem;
    background: #f6f8fa;
    border-left: 2px solid #364f6b;
}






