/*---------------------------*/
/*  ---- COLEGAS ------- */
/*---------------------------*/


/* -- border-box -- para mantener el tamaño de caja 
aunque se aplique padding*/

/****** DIV PRINCIPAL ***********/
.colega_div_1 {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 2rem;
  /* border: 1px solid red; */
}

/********** En Sesión Psic **************/
/**********COLEGAS*****************/

.colega_div_2 {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
  /* border: 1px solid rgb(12, 22, 161); */
}

.colega_sesion {
  box-sizing: border-box;
  width: 20%;
  display: flex;
  align-items: center;
  /* border: 1px solid blue; */
}

#lbl-tipoUsuario {
  font-weight: 600;
}


.div_lbl_colega {
  width: 60%;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  /* border: 1px solid green; */
}


/* ------div 3 ------ */

.colega_buscador {
  box-sizing: border-box;
  width: 100%;
  height: 10%;
  /* padding: 1rem; */
  display: flex;
  align-items: center;
  /* border: 1px solid black; */
}

/************************************/

.div_No_exp_buscar_colega {
  box-sizing: border-box;
  width: 30%;
  display: flex;
  /* border: 1px solid rgb(0, 255, 13); */
}

.input_No_colega {
  box-sizing: border-box;
  width: 20%;
  margin-right: 0.2rem;
  /* border: 1px solid rgb(0, 68, 255); */
}

/* input buscar */

.input_buscar {
  box-sizing: border-box;
  position: relative;
  width: 80%;
  margin-right: 2rem;

  /* border: 1px solid red; */
}

#Buscador {
  box-sizing: border-box;
  width: 100%;
  padding: 0.4rem;
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  outline: none;
  border-bottom: solid 1px #ccc;
}

#img_lupa {
  position: absolute;
  right: 0.8rem;
  top: 10%;
  /* transform: scaleY(-50%);
    transform: scaleX(-1); */
}

#Buscador::placeholder {
  font-size: 0.8rem;
}


/*---------- Selec filtro ---------- */
.colega_filtro {
  box-sizing: border-box;
  width: 35%;
  display: flex;
  align-items: center;
  /* border: 1px solid blue; */
}

.colega_lb_filtro {
  font-size: 0.8rem;
  margin-right: 15px;
  vertical-align: middle;
  /* border: 1px solid rgb(9, 255, 0); */
}

#colega_select {
  width: 30%;
  padding: 0.4rem;
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  outline: none;
  vertical-align: middle;
  color: #5e5e5e;
  /* border: 1px solid rgb(255, 0, 191); */
}

#colega_select option {
  font-size: 0.8rem;
  color: #363636;
}


/* btn limpiar filtro*/
#btn-limpiar-filtro {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 1.6rem;
  border: none;
  border-radius: 5px;
  color: aliceblue;
  font-size: 0.8rem;
  margin-left: 1rem;
  cursor: pointer;
  background-color: blueviolet;
}

#btn-limpiar-filtro img {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
}


/* boton paciente nuevo */
.colega_nuevo {
  width: 35%;
  display: flex;
  justify-content: end;
  margin-right: 1rem;
  /* border: 1px solid green; */
}

#colega_btn_nuevo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 1.6rem;
  border: none;
  border-radius: 5px;
  color: aliceblue;
  margin-left: 1rem;
  cursor: pointer;
  background-color: blueviolet;
}

#colega_btn_nuevo img {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
}


/* ------------------------------ */
/* Listado de pacientes */

.colega_div_3 {
  box-sizing: border-box;
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  /* border: 1px solid rgb(0, 47, 255); */
}

.div_colega_lista {
  box-sizing: border-box;
  width: 70%;
  height: 100%;
  overflow: auto;
  overflow-y: auto;
  margin-right: 1rem;
  /* border: 1px solid red; */
}


.div_tablas_th_colega {
  box-sizing: border-box;
  width: 100%;
  /* border: 1px solid rgb(0, 255, 13); */
}


/* ------------------------------ */

/*  DIV COLEGA TABLA ENCABEZADO DISPLAY  */

#table_display_encabezado_colega {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(0, 68, 255); */
}

/***** ENCABEZADOS DISPLAY DATA ******/

/* Encabezado <th> */
/* Espacios intermedios en el th */

.tr-encabezado-colega {
  width: 100%;
  background-color: rgb(236, 216, 255);
  border-collapse: collapse;
  text-align: left;
  font-weight: 600;
  /* border: 1px solid green; */
}

.tr-encabezado-colega th {
  padding: 0.8rem;
  text-align: center;
}

.tr-encabezado-colega th:first-child {
  width: 20%;
  text-align: left;
  font-size: 0.8rem;
}

.tr-encabezado-colega th:nth-child(2) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}

.tr-encabezado-colega th:nth-child(3) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}

/*-------------------------*/

/* TABLA ESPACIO CELDAS DISPLAY */
/* Espacio entre celdas de la fila */

#displayData_colega {
  box-sizing: border-box;
  width: 100%;
  /* border: 1px solid rgb(0, 68, 255); */
}

#displayData_colega td:first-child {
  width: 20%;
  padding-left: 0.6rem;
  text-align: left;
}

#displayData_colega td:nth-child(2) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}

#displayData_colega td:nth-child(3) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}



#table_colega_seleccionado {
  box-sizing: border-box;
  width: 100%;
  /* border: 1px solid red; */
}


/********** ToolTip TABLA colega *************/

.div-colega-toolTip {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  /* border: 1px solid red; */
}

#container-toolTip-editar-colega {
  box-sizing: border-box;
  width: 20%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  /* border: 1px solid rgb(0, 102, 255); */
}

.lbl-toolTip-editar-colega {
  visibility: hidden;
  box-sizing: border-box;
  position: absolute;
  font-size: 0.8rem;
  background-color: #5e5e5e;
  color: #fff;
  border-radius: 4px;
  padding: 0.2rem;
  z-index: 10;
  bottom: 100%;
  /* Posiciona el tooltip encima del elemento */
  transform: translateX(-20%);
  opacity: 0;
  transition: opacity 0.3s;
  /*border: 1px solid rgb(0, 255, 34); */
}


#container-toolTip-delete-colega {
  box-sizing: border-box;
  width: 20%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  /* border: 1px solid rgb(0, 102, 255); */
}

.lbl-toolTip-delete-colega {
  visibility: hidden;
  box-sizing: border-box;
  position: absolute;
  visibility: visible;
  font-size: 0.8rem;
  background-color: #5e5e5e;
  color: #fff;
  border-radius: 4px;
  padding: 0.2rem;
  z-index: 10;
  bottom: 100%;
  /* Posiciona el tooltip encima del elemento */
  transform: translateX(-20%);
  opacity: 0;
  transition: opacity 0.3s;
}

/* regla CSS para mostrar el tooltip cuando se pasa 
el cursor sobre el elemento */

#container-toolTip-editar-colega:hover .lbl-toolTip-save-colega {
  visibility: visible;
  opacity: 1;
}

#container-toolTip-delete-colega:hover .lbl-toolTip-delete-colega {
  visibility: visible;
  opacity: 1;
}

/*********************************/


/******  TABLA colega  ****************/

.colega_tabla {
  box-sizing: border-box;
  width: 30%;
  /* height: 100%; */
  /* border: 1px solid red; */
}

.colega_tabla #edit_delete {
  box-sizing: border-box;
  width: 100%;
  /* height: 15%; */
  display: flex;
  background-color: rgb(236, 216, 255);
  /* border: 1px solid red; */
}

#colega_h3 {
  box-sizing: border-box;
  width: 50%;
  display: flex;
  text-align: center;
  justify-content: cen;
  padding: 0.5rem;
  padding-left: 1rem;
  /* border: 1px solid blue; */
}

#lbl_datos_colega {
  font-size: 0.8rem;
  font-weight: 700;
}


/* Div tabla sin datos */

.div-selec-colega {
  width: 100%;
  /* height: 30%; */
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}


#label-selec-colega-colega {
  font-size: 0.8rem;
}