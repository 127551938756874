/* ---- ADD PACIENTES --------*/
/* ----  ------------ --------*/


.div-1-addPx {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}

.px_div_2_addPx {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(12, 22, 161); */
}

#lbl-enSesion_addPx {
  font-weight: 600;
}

#lbl-enSesion_addPx label {
  font-size: 0.8rem;
  font-weight: 400;
}

.div_lbl_px_addPx {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  /* border: 1px solid rgb(0, 23, 128); */
}

.div_lbl_px_addPx label {
  font-size: 2rem;
  font-weight: 600;
}


/***** Div Datos Paciente *******/

.div-datos-addPx {
  box-sizing: border-box;
  width: 50%;
  margin: auto;
  margin-bottom: 3rem;
  /* border: 1px solid rgb(0, 23, 128); */
}

.div_content_foto_addPx {
  box-sizing: border-box;
  width: 100%;
  height: 30%;
  display: flex;
  /* border: 1px solid rgb(51, 255, 0); */
}

.div-foto-px-addPx {
  box-sizing: border-box;
  text-align: center;
  width: 30%;
  height: 100%;
  border: 1px solid red;
}

.div-foto-px-addPx img {
  width: 10rem;
  height: 7rem;
}

.div_btn_foto_addPx {
  box-sizing: border-box;
  padding: 1rem;
  width: 50%;
  display: flex;
  align-items: end;
  justify-content: left;
  /* border: 1px solid rgb(0, 89, 255); */
}

.div_btn_quitar_addPx {
  box-sizing: border-box;
  width: 20%;
  margin: auto;
  /* border: 1px solid rgb(240, 46, 165); */
}


/****** TABLA DATOS *******/

.div_tabla_addPx {
  box-sizing: border-box;
  /* border: 1px solid #002168; */
}

.div_tabla_1_addPx {
  box-sizing: border-box;
  /* border: 1px solid #2074ac; */
}

.div_tabla_2_addPx {
  box-sizing: border-box;
  /* border: 1px solid #7c08a0; */
}

.div_tabla_3_addPx {
  box-sizing: border-box;
  /* border: 1px solid #006809; */
}

.table-addPx {
  box-sizing: border-box;
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px 5px;
  /* border: 1px solid #006809; */
}

/* ------ Tabla 2 ------ */
.table-2-addPx {
  box-sizing: border-box;
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px 5px;
  /* border: 1px solid #006809; */
}

.addPx-t2-izq {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  background-color: rgb(255, 221, 180);
}

.addPx-t2-der {
  box-sizing: border-box;
  width: 70%;
}

.addPx-t2-der input {
  box-sizing: border-box;
  font-size: 0.8rem;
  width: 100%;
  height: 2rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
  padding-left: 0.4rem;
}

/* ----- Tabla 3 ----- */
.table-3-addPx {
  box-sizing: border-box;
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px 5px;
  /* border: 1px solid #006809; */
}

.addPx-t3-izq {
  box-sizing: border-box;
  width: 5%;
  height: 2rem;
  background-color: rgb(255, 221, 180);
}

.addPx-t3-der {
  box-sizing: border-box;
  width: 20%;
}

.addPx-t3-der input {
  box-sizing: border-box;
  border: 1px solid gray;
  /* outline: none; */
  border-radius: 0.3rem;
  padding: 0.2rem;
  font-size: 0.8rem;
  padding-left: 0.4rem;
  /* background-color: transparent; */
}

#select-edo-addPx,
#select-pais-addPx {
  width: 35%;
  font-size: 0.8rem;
  padding: 0.2rem;
  outline: none;
  color: #494949;
}

/* --------------------- */


/****** TR ********/
/****** TD ********/

.addPx-px-td-izq {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  background-color: rgb(255, 221, 180);
}

.addPx-px-td-der1 {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}

.addPx-px-td-der2 {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}

.addPx-px-td-der3 {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}

/****** TD select *******/

.addPx-px-td-select {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
}

/***** INPUT ******/

.addPx-px-td-der1 input {
  box-sizing: border-box;
  border: 1px solid gray;
  /* outline: none; */
  border-radius: 0.3rem;
  padding: 0.2rem;
  font-size: 0.8rem;
  padding-left: 0.4rem;
  /* background-color: transparent; */
}

.addPx-px-td-der2 input {
  box-sizing: border-box;
  border: 1px solid gray;
  /* outline: none; */
  border-radius: 0.3rem;
  padding: 0.2rem;
  font-size: 0.8rem;
  padding-left: 0.4rem;
  /* background-color: transparent; */
}

.addPx-px-td-der3 input {
  box-sizing: border-box;
  border: 1px solid gray;
  /* outline: none; */
  border-radius: 0.3rem;
  padding: 0.2rem;
  font-size: 0.8rem;
  padding-left: 0.4rem;
  /* background-color: transparent; */
}


/********* RADIO BUTTOM **********/

.radio-container label {
  font-size: 0.8rem;
  color: #494949;
  margin-right: 0.6rem;
}


/************************/

/*  ID Select */

#select-sexo-addPx,
#select-escolaridad-addPx,
#select-prefSex-addPx,
#select-edo-civil-addPx {
  width: 100%;
  font-size: 0.8rem;
  padding: 0.2rem;
  outline: none;
  color: #494949;
}


#select-sexo-addPx option,
#select-escolaridad-addPx option,
#select-pref_sex-addPx option,
#select-edo-civil-addPx option,
#select-edo-addPx option,
#select-pais-addPx option {
  font-size: 0.8rem;
  color: #000000;
}

/* ----------------- */


/*---------------------------*/
/* Div botones: ENVIAR CANCELAR*/

/*   div Padre   autoreg y botones   */

.div-autoregistro-botones-addPx {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 2rem;
  /* border: 1px solid blue; */
}

.div-btn-alineacion-addPx {
  box-sizing: border-box;
  margin: auto;
  width: 20%;
  display: flex;
  /* border: 1px solid blue; */
}


/* Div botones: ENVIAR CANCELAR*/
.div-addPx-enviar {
  box-sizing: border-box;
  width: 50%;
  margin: auto;
  /* border: 1px solid rgb(5, 101, 165); */
}

.div-addPx-cancelar {
  box-sizing: border-box;
  width: 50%;
  margin: auto;
  /* border: 1px solid rgb(5, 101, 165); */
}


#btn-enviar-addPx,
#btn-cancelar-addPx,
#btn_quitarImg_addPac {
  border-radius: 0.4rem;
  border: 1px solid gray;
  cursor: pointer;
}

/*----------------------------------------*/