/* -------- PSICOLOGO --------*/

/* -------- Div principales -------- */

.psi_div_1 {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}

.psi_div_2 {
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  margin-bottom: 2rem;
  /* border: 1px solid rgb(12, 22, 161); */
}

.psi_sesion {
  box-sizing: border-box;
  width: 50%;
  /* border: 1px solid blue; */
}

#lbl-enSesion-psi {
  font-weight: 600;
}

.div_lbl_psi {
  box-sizing: border-box;
  margin: auto;
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  /* border: 1px solid green; */
}

/******************************/

.div-datos-psi {
  width: 50%;
  margin: auto;
  /* border: 1px solid green; */
}

/********** AVATAR ***********/

.div-foto-psi {
  box-sizing: border-box;
  width: 100%;
  margin: auto;
  display: flex;
  margin-bottom: 0.4rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
}

.div-avatar-psi {
  box-sizing: border-box;
  width: 30%;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
}

.div-avatar-psi img {
  width: 30%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid gray;
  box-shadow: 4px 4px 6px 0;
}

.div-label-psi {
  box-sizing: border-box;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: end;
  font-weight: 600;
  /* border: 1px solid rgb(195, 0, 255); */
}



/********  TOOLTIP ***********/

#container-toolTip-editar-psi {
  box-sizing: border-box;
  width: 20%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  /* border: 1px solid rgb(0, 102, 255); */
}

.lbl-toolTip-editar-psi {
  visibility: hidden;
  box-sizing: border-box;
  position: absolute;
  font-size: 0.8rem;
  background-color: #5e5e5e;
  color: #fff;
  border-radius: 4px;
  padding: 0.2rem;
  z-index: 10;
  bottom: 100%;
  /* Posiciona el tooltip encima del elemento */
  transform: translateX(-20%);
  opacity: 0;
  transition: opacity 0.3s;
  /*border: 1px solid rgb(0, 255, 34); */
}


/* regla CSS para mostrar el tooltip cuando se pasa 
el cursor sobre el elemento */

#container-toolTip-editar-psi:hover .lbl-toolTip-editar-psi {
  visibility: visible;
  opacity: 1;
}


/**********************************/



/* ------- TABLA DE DATOS ------ */


.div-table-label-psi {
  box-sizing: border-box;
  width: 100%;
  margin: auto;
  margin-bottom: 0.2rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
  background-color: rgb(255, 232, 204);
}

.div-table-psi {
  box-sizing: border-box;
  width: 100%;
  margin: auto;
  padding: 1rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
  background-color: rgb(255, 232, 204);
  box-shadow: 2px 2px 3px 0;
}

.div-table-label-psi {
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem;
}


.table-psi {
  box-sizing: border-box;
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 5px 10px;
  /* border: 1px solid #006809; */
}


.psi-td-izq {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  background-color: rgb(255, 221, 180);
}

.psi-td-der {
  box-sizing: border-box;
  width: 30%;
  height: 2rem;
  border-bottom: solid 1px #ccc;
}

.psi-td-der label {
  font-size: 0.8rem;
}

/************************/


/****** displays ********/

.div-displays-psic {
  width: 80%;
  margin: auto;
  display: flex;
  /* border: 1px solid red; */
}

.div-displaysAct {
  box-sizing: border-box;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-bottom: 0.3rem;
  width: 100%;
  margin-top: 0.4rem;
  border-radius: 0.5rem;
  border: 1px solid gray;
  background-color: rgb(235, 229, 211);
}

.div-displaysInAct {
  box-sizing: border-box;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-bottom: 0.3rem;
  width: 100%;
  margin-top: 0.4rem;
  border-radius: 0.5rem;
  border: 1px solid gray;
  background-color: rgb(235, 229, 211);
}

.div-displays-pAct {
  width: 45%;
  font-size: 0.8rem;
  margin-top: 1.6rem;
  margin-right: 0.5rem;
  flex-direction: column;
  /* border: 1px solid red; */
}

.div-displays-pAct-label label {
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.6rem;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid rgb(162, 158, 202);
}

.div-displays-pInAct {
  width: 55%;
  font-size: 0.8rem;
  margin-top: 1.6rem;
  margin-left: 0.5rem;
  flex-direction: column;
  /* border: 1px solid red; */
}

.div-displays-pInAct-label label {
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.6rem;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid rgb(162, 158, 202);
}


/*  boton Display    */
#liga_psi {
  border-style: none;
  border-radius: 0.3rem;
  border: 1px solid gray;
}


/* tabla display   */
.table-displays-psi {
  width: 100%;
}

/*  filas y celdas    */

#tr-psi {
  width: 100%;
  border-bottom: 1px solid gray;
}

#td_1-psi-a {
  padding: 0.4rem;
  width: 60%;
}

#td_2-psi-a {
  padding: 0.4rem;
  width: 20%;
}

#td_3-psi-a {
  padding: 0.4rem;
  width: 20%;
}

/*--------------------------*/
#td_1-psi-i {
  padding: 0.4rem;
  width: 45%;
}

#td_2-psi-i {
  padding: 0.4rem;
  width: 5%;
}

#td_3-psi-i {
  padding: 0.4rem;
  width: 10%;
}

#td_4-psi-i {
  padding: 0.4rem;
  width: 35%;
}



/* Div botones: ENVIAR CANCELAR*/

.div-psi-botones {
  box-sizing: border-box;
  width: 40%;
  font-size: 0.8rem;
  padding: 0.4rem;
  box-shadow: 1px 1px 1px rgba(32, 32, 32, 0.3);
  border-radius: 0.4rem;
  border: none;
  display: flex;
  justify-content: center;
  cursor: pointer;
  /* border: 1px solid rgb(5, 101, 165); */
}

/*----------------------------------------*/





/*-------- MODAL ----------*/
/*------------------/*------------------*/

.table-cambiar-contrasena {
  width: 100%;
  border: 1px solid #696969;
}

.table-cambiar-contrasena td:first-child {
  width: 30%;
  font-size: 0.8rem;
  background-color: bisque;
}

.table-cambiar-contrasena td:nth-child(2) {
  width: 70%;
  font-size: 0.8rem;
  background-color: rgb(252, 240, 226);
}


#td-psi-contrasena {
  text-align: left;
}

/* label  derecha MODAL*/
.lbl-px-contrasena {
  padding: 1rem;
  color: #363636;
  font-weight: 600;
  cursor: pointer;
}

#td-input-contrasena {
  padding: 0.6rem;
  text-align: left;
}

#td-time-contrasena {
  padding: 0.4rem;
  text-align: left;
  border: none;
  outline: none;
}

#td-time-contrasena input {
  width: 40%;
}

#input-fecha-contrasena {
  border: none;
  outline: none;
  background-color: transparent;
}


.input-contrasena {
  box-sizing: border-box;
  width: 80%;
  padding: 0.4rem;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  border: none;
  outline: none;
}

/*----------------------*/

/* ------- MODAL Eliminar PX Sys --------- */

.datos-px-modal-eliminar {
  width: 100%;
}

.div-avatar-eliminar {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
}

.div-imgPac-eliminar {
  width: 50%;
}

.div-imgPac-eliminar img {
  width: 9rem;
  height: 8rem;
}

.div-labelPac-eliminar {
  width: 50%;
  margin: auto;
}

.div-labelPac-eliminar label {
  font-size: 1rem;
}

/* ----- Tabla eliminar ----- */
.modal-pax-eliminar {
  width: 90%;
  margin: auto;
}

.modal-pax-eliminar tr {
  width: 100%;
  padding: 0.4rem;
  border-bottom: 1px solid gray;
  /* border: 1px solid red; */
}

.lbl-eliminar-izq {
  width: 50%;
  text-align: left;
  font-size: 0.8rem;
  padding-left: 0.4rem;
  font-weight: 600;
  /* border: 1px solid red; */
}

#lbl-eliminar-der {
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
  padding-left: 0.4rem;
  /* border: 1px solid red; */
}