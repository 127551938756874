/*---------------------------*/
/*  ---- FAMILIARES ------- */
/*---------------------------*/

/*Div principal*/
.px_div_1_familiares {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 2rem;
  /* border: 1px solid red; */
}

.px_div_2_fam {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(161, 12, 141); */
}

.px_sesion_fam {
  box-sizing: border-box;
  width: 50%;
  /* border: 1px solid blue; */
}

.px_sesion_fam #lbl-enSesion {
  font-weight: 600;
}

.div-Fam-Buscar-fam {
  width: 100%;
  margin: auto;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid blue; */
}

.div_lbl_fam {
  box-sizing: border-box;
  width: 50%;
  text-align: center;
  /* border: 1px solid green; */
}

/*******************************/



/******  Select Filtro y Btn new fam ******/

.div_filtro_selec_px_fam {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  /* border: 1px solid rgb(119, 0, 255); */
}


/********** Select ***********/

.input_buscar1_fam {
  width: 25%;
  /* border: 1px solid red; */
}

.input_buscar2_fam {
  width: 100%;
  position: relative;
  /* border: 1px solid violet; */
}

#lbl_familiares {
  font-size: 2rem;
  font-weight: 600;
}

.px_filtro_fam {
  box-sizing: border-box;
  width: 20%;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  /* border: 1px solid rgb(255, 0, 0); */
}

#px_select_fam {
  width: 100%;
  padding: 0.4rem;
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  outline: none;
  vertical-align: middle;
  color: #5e5e5e;
  /* border: 1px solid rgb(255, 0, 191); */
}

#px_select_fam option {
  font-size: 0.8rem;
  color: #363636;
}


/* botones */

.div-botones-cls-newPax {
  box-sizing: border-box;
  width: 36%;
  display: flex;
  /* border: 1px solid red; */
}


#div_btn-cls_fam {
  box-sizing: border-box;
  width: 30%;
  margin-left: 1rem;
  /* border: 1px solid rgb(107, 86, 15); */
}


/* btn limpiar filtro*/
#btn-limpiar-filtro-fam {
  display: flex;
  font-size: 0.8rem;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 1.6rem;
  border: none;
  border-radius: 5px;
  color: aliceblue;
  cursor: pointer;
  background-color: blueviolet;
}

#btn-limpiar-filtro-fam img {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
}

/* ------------------------------ */

/*------Botón nuevo fam ---------*/

#div_nuevoFam_fam {
  box-sizing: border-box;
  width: 75%;
  display: flex;
  justify-content: end;
  /* border: 1px solid green; */
}

/* ------------------------------ */
/*******************************/




/*********   Buscar por pacientes **********/
/* ------------------------------ */

.div-buscarPorPax {
  box-sizing: border-box;
  width: 100%;
  /* border: 1px solid gray; */
}


/* Encabezado de pacientes */

.px_div_3_fam {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(0, 47, 255); */
}

.px_div_3_fam label {
  padding: 0.4rem;
  font-weight: 600;
}

#px_Pac_fam {
  box-sizing: border-box;
  width: 25%;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid gray;
}

.div_tablas_th_fam {
  box-sizing: border-box;
  width: 48%;
  padding: 0.4rem;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid gray;
}

/* Encabezado <th> */
/* Espacios intermedios en el th */

#encabezado_familiar {
  box-sizing: border-box;
  width: 100%;
  border-collapse: collapse;
  font-weight: 400;
  font-size: 1rem;
}

#tr-encabezado-fam-1 th:first-child {
  width: 40%;
  font-weight: 600;
  /* border: 1px solid gray; */
}

#tr-encabezado-fam-1 th:nth-child(2) {
  width: 20%;
  text-align: center;
  font-weight: 600;
  /* border: 1px solid gray; */
}

#tr-encabezado-fam-1 th:nth-child(3) {
  width: 20%;
  text-align: center;
  font-weight: 600;
  /* border: 1px solid gray; */
}

#tr-encabezado-fam-1 th:nth-child(4) {
  width: 20%;
  text-align: center;
  font-weight: 600;
  /* border: 1px solid gray; */
}


/*-----------------------------------*/

#tr-encabezado-fam-2 th:first-child {
  width: 60%;
  font-weight: 600;
  /* border: 1px solid gray; */
}

#tr-encabezado-fam-2 th:nth-child(2) {
  width: 20%;
  text-align: center;
  font-weight: 600;
  /* border: 1px solid gray; */
}




/*-------------------------*/

#edit_delete_fam {
  box-sizing: border-box;
  width: 25%;
  display: flex;
  border-radius: 0.3rem;
  border: 1px solid gray;
  background-color: rgb(236, 216, 255);
}

#edit_delete2_fam {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
}

/****************************************/


/****************************************/
/*  display y tablas   */

.div-display-tabla-fam {
  width: 100%;
  display: flex;
  /* border: 1px solid rgb(9, 255, 0); */
}

/* display 1 pac */
.div_tablas_th_px-fam {
  box-sizing: border-box;
  width: 25%;
  border-radius: 0.3rem;
  padding: 0.6rem;
  background-color: rgb(255, 214, 163);
  border: 1px solid gray;
}

/* display pacientes */
#filas_px-fam {
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px 5px;
}


#td-paxDelfam-fam {
  width: 100%;
  padding: 0.2rem;
}


#lbl_radio_fam {
  margin-left: 0.6rem;
  /* border: 1px solid rgb(9, 255, 0); */
}



/******** Div Tabla displayData 2 y 3 **********/

.div-table-display2 {
  width: 50%;
  display: flex;
  /* border: 1px solid rgb(19, 0, 128); */
}

#div-display1y2-fam {
  width: 100%;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  /* border: 1px solid rgb(128, 0, 107); */
}


/* Familiares no pac*/
#div-display1-fam {
  width: 100%;
  border-radius: 0.3rem;
  background-color: rgb(255, 215, 167);
  border: 1px solid gray;
}


/* Familiares sin parentesco */
#div-display1-fam4 {
  width: 100%;
  margin-top: 0.4rem;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  /* border: 1px solid gray; */
}

#div-display2-fam4 {
  width: 100%;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid gray;
}

/* Paciente que es fam de otro pac */
#div-display2-fam {
  width: 100%;
  border-radius: 0.3rem;
  background-color: rgb(255, 215, 167);
  border: 1px solid gray;
}


/* Espacio entre celdas de la fila */

#filas_familiar {
  width: 100%;
  /* border: 1px solid rgb(19, 0, 128); */
}

#filas_familiar td:first-child {
  width: 20%;
  /* border: 1px solid rgb(19, 0, 128); */
}

#filas_familiar td:nth-child(2) {
  width: 10%;
  /* border: 1px solid rgb(19, 0, 128); */
}

#filas_familiar td:nth-child(3) {
  width: 10%;
  /* border: 1px solid rgb(19, 0, 128); */
}

#filas_familiar td:nth-child(4) {
  width: 10%;
  /* border: 1px solid rgb(19, 0, 128); */
}

/* dentro de const DisplayData */
#td_2_fam,
#td_3_fam,
#td_4_fam,
#td_5_fam {
  font-size: 0.8rem;
  padding: 0.6rem;
  text-align: center;
}

#td_4_fam button {
  background-color: bisque;
  border: none;
}

#td_4_fam button img {
  margin: auto;
  /* border: 1px solid rgb(19, 0, 128); */
}

/******************************************/



/******  DIV de TABLA PX  ****************/

.px_tabla_fam {
  box-sizing: border-box;
  width: 25%;
  height: 100%;
  padding: 0.6rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
  background-color: rgb(255, 214, 163);
}

.tr-bottom-pac-fam {
  border-bottom: 1px solid gray;
}

#td-padding-fam {
  padding-bottom: 0.4rem;
}


#px_h3_fam {
  box-sizing: border-box;
  width: 50%;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
}

#px_h3_fam label {
  font-size: 1rem;
  font-weight: 600;
  padding-left: 0.4rem;
}

#e-d_label_fam {
  box-sizing: border-box;
  width: 50%;
  display: flex;
  justify-content: end;
  /* border: 1px solid red; */
}


/*************** TABLA PX ***************/

#table_px_seleccionado_fam {
  box-sizing: border-box;
  width: 100%;
  /* border: 1px solid rgb(0, 81, 255); */
}


/************* Nombre Fam tabla *********/

#td-paciente-fam {
  padding: 1rem;
}

#lbl-paciente-fam {
  font-size: 1rem;
  font-weight: 600;
}

/* ------------------------------ */





/*********   Realizar búsqueda por familiar **********/
/* ------------------------------ */

.div-buscarPorFam-2 {
  width: 100%;
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;
  /* border: 1px solid red; */
}

.lbl-buscarPorFam-2 {
  width: 30%;
  margin-left: 2rem;
  /* border: 1px solid red; */
}

.lbl-buscarPorFam-2 label {
  width: 100%;
  font-weight: 600;
  padding: 0.4rem;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid gray;
}

.lbl-buscarPorFam-3 {
  width: 15%;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid gray;
}

.lbl-buscarPorFam-3 label {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.lbl-buscarPorFam-3 input {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

#id-radio1-buscarFam {
  margin-right: 0.4rem;
  /* border: 1px solid red; */
}



/******** Display Familiares *****************/

.div-buscarFam-2 {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* border: 1px solid red; */
}


/* busacardor familiar No Pac */

.input_buscador_fam {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(0, 47, 255); */
}

.input_buscar_fam2 {
  width: 30%;
  /* border: 1px solid red; */
}

.input_buscar2_fam2 {
  width: 100%;
  position: relative;
  /* border: 1px solid red; */
}

/**********************************/


.px_div_3_fam2 {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-bottom: 0.2rem;
  /* border: 1px solid rgb(0, 47, 255); */
}


/* encabezado display 1 pac */

.div_tablas_th_Pac {
  box-sizing: border-box;
  width: 50%;
  padding: 0.4rem;
  margin-right: 1rem;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid gray;
}

.div_tablas_Pac-rel {
  width: 50%;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid gray;
}

.div_tablas_Pac-rel label {
  font-weight: 600;
  padding-top: 0.4rem;
  padding-left: 0.6rem;
}




/* display 1 fam */

.div-display-tabla-fam {
  width: 100%;
  display: flex;
  /* border: 1px solid red; */
}

.div_tablas_th_PAC-fam {
  box-sizing: border-box;
  width: 50%;
  margin-right: 1rem;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid gray;
}


/* display Familiares */
#filas_px-fam2 {
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px 5px;
  /* border: 1px solid rgb(0, 4, 255); */
}


#td-paxDelfam-fam2 {
  width: 40%;
  padding: 0.2rem;
  /* border: 1px solid rgb(9, 255, 0); */
}


#lbl_radio_fam {
  margin-left: 0.6rem;
  /* border: 1px solid rgb(9, 255, 0); */
}

/* dentro de const DisplayData */
#td_2_fam2 {
  width: 20%;
  font-size: 0.8rem;
  padding: 0.6rem;
  /* border: 1px solid rgb(9, 255, 0); */
}

#td_3_fam2 {
  width: 30%;
  font-size: 0.8rem;
  padding: 0.6rem;
  /* border: 1px solid rgb(9, 255, 0); */
}

#td_4_fam2 {
  width: 10%;
  font-size: 0.8rem;
  padding: 0.6rem;
  text-align: center;
  /* border: 1px solid rgb(9, 255, 0); */
}

#td_4_fam2 button {
  background-color: rgb(236, 216, 255);
  border: none;
}

#td_4_fam2 button img {
  margin: auto;
  /* border: 1px solid rgb(19, 0, 128); */
}




/* Content de display 2 y 3 pac */

.div-table-display2-PAC {
  width: 50%;
  display: flex;
  /* border: 1px solid rgb(19, 0, 128); */
}


#div-display1-fam2 {
  width: 100%;
  border-radius: 0.3rem;
  background-color: rgb(255, 216, 216);
  border: 1px solid gray;
}


/* --- display 2 y 3 pac ---- */

.div-nom-foto-parent-famNP {
  width: 100%;
  padding: 0.4rem;
  /* border: 1px solid rgb(128, 0, 107); */
}

.div-btn-parent-famNP {
  box-sizing: border-box;
  width: 100%;
  margin-top: 0.6rem;
  display: flex;
  /* border: 1px solid rgb(128, 0, 107); */
}

#div-btn1-parent-famNP {
  width: 50%;
}

#div-btn1-parent-famNP button {
  border: none;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  padding: 0.4rem;
  margin-left: 0.4rem;
  border: 1px solid gray;
}

#div-btn2-parent-famNP {
  width: 50%;
  text-align: end;
}

#div-btn2-parent-famNP button {
  border: none;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  padding: 0.4rem;
  margin-right: 0.4rem;
  border: 1px solid gray;
}



.lbl-nom-parent-famNP {
  width: 100%;
  margin-top: 0.4rem;
  margin-left: 1rem;
}

.lbl-nom-parent-famNP label {
  font-size: 1rem;
}

.div-nom-foto-famNP {
  box-sizing: border-box;
  width: 100%;
  padding: 0.4rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  /* border: 1px solid rgb(0, 128, 28); */
}

.div-img-foto-famNP {
  width: 50%;
  text-align: center;
  border-radius: 0.3rem;
  padding: 0.4rem;
  /* border: 1px solid gray; */
}

.div-img-foto-famNP img {
  width: 5rem;
  height: 5rem;
  box-shadow: 4px 4px 6px 0;
}

.div-lbl-nombre-famNP {
  width: 50%;
  text-align: center;
  /* border: 1px solid rgb(0, 128, 28); */
}

.div-lbl-nombre-famNP label {
  font-weight: 600;
}

/*---------------------*/

/*    MODAL FAMILIAR    */
.datos-paciente-modal {
  /* border: 1px solid rgb(0, 128, 28); */
}

.div-select-paciente-modal {
  width: 100%;
  margin-bottom: 0.4rem;
  /* border: 1px solid rgb(0, 128, 28); */
}

#select-pac-fam-fam {
  width: 100%;
  font-size: 0.8rem;
  padding: 0.2rem;
  outline: none;
  color: #494949;
  border-radius: 0.3rem;
}

.div-select-fam-modal {
  width: 100%;
  /* border: 1px solid rgb(0, 128, 28); */
}

#select-parentescoDelFam-fam {
  width: 100%;
  font-size: 0.8rem;
  padding: 0.2rem;
  outline: none;
  color: #494949;
  border-radius: 0.3rem;
}

.datos-foto-modal {
  margin-top: 0.6rem;
}


/*---------------------*/
/*---------------------*/
/*---------------------*/


.div-table-datosfamNP {
  width: 100%;
  padding: 0.4rem;
  /* border: 1px solid rgb(128, 0, 107); */
}

#id-table-datosfamNP {
  font-size: 0.8rem;
  border-collapse: separate;
  border-spacing: 10px 5px;
  width: 100%;
}

#tr-table-datosfamNP {
  width: 100%;
}

#td-table-datosfamNP {
  width: 20%;
  font-weight: 600;
}



/***** Familiares sin parentesco *****/

.div_5_fam2 {
  box-sizing: border-box;
  width: 100%;
  margin-top: 2.6rem;
  /* border: 1px solid rgb(0, 47, 255); */
}

.px_div_5_fam2 {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-bottom: 0.2rem;
  /* border: 1px solid rgb(0, 47, 255); */
}



/***** Familiares que son pacientes *****/

.div_4_fam2 {
  box-sizing: border-box;
  width: 100%;
  margin-top: 2.6rem;
  /* border: 1px solid rgb(0, 47, 255); */
}

.px_div_4_fam2 {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-bottom: 0.2rem;
  /* border: 1px solid rgb(0, 47, 255); */
}


.div_tablas_th_Pac4 {
  box-sizing: border-box;
  width: 50%;
  padding: 0.4rem;
  margin-right: 1rem;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid gray;
}

.div_tablas_Pac-rel4 {
  width: 50%;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid gray;
}

.div_tablas_Pac-rel4 label {
  font-weight: 600;
  padding-top: 0.4rem;
  padding-left: 0.6rem;
}


.div_tablas_th_PAC-fam4 {
  box-sizing: border-box;
  width: 50%;
  margin-right: 1rem;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid gray;
}


#td_4_fam4 button {
  background-color: rgb(255, 216, 216);
  border: none;
}

#td_4_fam4 button img {
  margin: auto;
  /* border: 1px solid rgb(19, 0, 128); */
}



