/* -------- TRANSFERIR PACIENTES --------*/


.div-Px-SelectPsi-transfer {
  width: 100%;
  flex-direction: column;
  padding: 0.4rem;
  margin-bottom: 0.6rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
}

.div-avatarPx-transfer {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
  /* border: 1px solid violet; */
}

.div-avatarPx-tf {
  width: 30%;
  padding: 0.4rem;
  border: 1px solid gray;
}

.div-avatarPx-tf img {
  width: 90%;
  margin: 0.2 0.2 0.2 0.2;
  /* border: 1px solid gray; */
  box-shadow: 4px 4px 6px 0;
}

.div-lblPx-transfer {
  width: 70%;
  text-align: left;
  font-size: 1rem;
  padding: 0.6rem;
  /* border: 1px solid green; */
}

.div-lbl-Select-transfer {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
  /* border: 1px solid violet; */
}

.div-lblPsi-transfer {
  width: 20%;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.4rem;
  /* border: 1px solid purple; */
}

.div-selectPsi-transfer {
  width: 70%;
  text-align: left;
  font-size: 0.8rem;
  padding-left: 0.6rem;
  /* border: 1px solid brown; */
}

.div-selectPsi-transfer select {
  width: 95%;
  padding: 0.4rem;
  border-radius: 0.3rem;
}


/*------------------------------*/

.div-motivo-fecha-transf {
  width: 100%;
  border-radius: 0.3rem;
  border: 1px solid gray;
}

.div-motivo-transf {
  width: 100%;
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
}

.div-motivo1-transf {
  width: 20%;
  font-size: 1rem;
  font-weight: 600;
  /* border: 1px solid red; */
}

.div-motivo2-transf {
  width: 80%;
  font-size: 0.8rem;
  /* border: 1px solid red; */
}

.div-motivo2-transf textarea {
  width: 95%;
  padding: 0.4rem;
  border-radius: 0.3rem;
}

.div-fecha-transf {
  width: 100%;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
}

.div-fecha1-transf {
  width: 20%;
  font-size: 1rem;
  font-weight: 600;
  /* border: 1px solid red; */
}

.div-fecha2-transf {
  width: 70%;
  padding: 0.4rem;
  font-size: 0.8rem;
  /* border: 1px solid red; */
}