
/* estilo de React
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/


/*   Activo    */
/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 16px;
} */




html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#root {
  box-sizing: border-box;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  /* border: 1px solid greenyellow; */
  width: 100%;
  height: 100%;
}


/*---------------------------*/
/*--------- LOGIN ------------*/
/*---------------------------*/

.Layout-login {
  box-sizing: border-box;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.header_login {
  background-color: #66007a;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 15%;
  /*overflow: hidden;*/
}


/* .content-login {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 85%;
  border: 1px solid rgb(47, 255, 158);
} */



/*---------------------------*/
/* -------- COMPONENTES ----------*/
/*---------------------------*/

.Layout {
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: bisque;
  /* border: 0.1px solid red; */
}

.div-layout-header {
  box-sizing: border-box;
  position: fixed;
  z-index: 30;
  width: 100%;
  min-height: 15%;
  top: 0;
  /* background-color: aquamarine; */
}

.div-layout-content {
  box-sizing: border-box;
  /* position: absolute; */
  margin-top: 8.5%;
  z-index: 10;
  width: 100%;
  /* height: 100%; */
  /* border: 1px solid rgb(47, 255, 158); */
  /* background-color: rgb(255, 145, 0); */
}

.div-layout-footer {
  box-sizing: border-box;
  /* position: absolute; */
  /* bottom: 0; */
  /* z-index: 20; */
  width: 100%;
  margin-top: auto;   /* permite autoajuste si Layout trabaja con flex */
  /* height: 10%; */
  /* border: 1px solid rgb(3, 70, 39);
  background-color: rgba(104, 59, 1, 0.582); */
}


/******** Header ******/
.div-header {
  background-color: #66007a;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  /*overflow: hidden;*/
}






