

/* -------- EDITAR PACIENTES --------*/


/* -------- Div principales -------- */

.div-1-edPsi {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}

.div_2_edPsi {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 2rem;
  /* border: 1px solid red; */
}

.sesion_edPsi {
  box-sizing: border-box;
  width: 20%;
}

#lbl-enSesion-edPsi {
 font-weight: 600;
}

#lbl-psi-edPsi {
  font-weight: 400;
}

.div_lbl_edPsi {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  /* border: 1px solid red; */
}

.div_lbl_edPsi label {
  font-size: 2rem;
  font-weight: 600;
}





/************ DIV TABLA ************/

.div-datos-edPsi {
  box-sizing: border-box;
  width: 50%;
  margin: auto;
  margin-bottom: 3rem;
  /* border: 1px solid red; */
}


/********** AVATAR ***********/

.div_content_foto_edPsi {
  box-sizing: border-box;
  width: 100%;
  height: 30%;
  display: flex;
  /* border: 1px solid rgb(51, 255, 0); */
}

.div-foto-edPsi {
  box-sizing: border-box;
  text-align: center;
  width: 30%;
  height: 100%;
  /* border: 1px solid red; */
}

.div-foto-edPsi img {
  box-sizing: border-box;
  padding: 1rem;
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
}

.div_btn_foto_edPsi {
  box-sizing: border-box;
  padding: 1rem;
  width: 70%;
  display: flex;
  align-items: end;
  justify-content: left;
  /* border: 1px solid rgb(0, 89, 255); */
}


/**********************************/


/* ------- TABLA DE DATOS ------ */

.div_tabla_edPsi {
  box-sizing: border-box;
  /* border: 1px solid #002168; */
}

.table-edPsi {
  box-sizing: border-box;
  width: 100%;
  border-collapse: separate;
  border-spacing: 5px 10px;
  /* border: 1px solid #006809; */
}


/****** TD ********/

.edPsi-td-izq {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  background-color: rgb(255, 221, 180);
}

.edPsi-td-der1 {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}

.edPsi-td-der2 {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}

.edPsi-td-der3 {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}


/****** TD select *******/

.edPsi-td-select {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
}

/***** INPUT ******/

.edPsi-td-der1 input {
  box-sizing: border-box;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  font-style: italic;
  padding: 0.4rem;
}

.edPsi-td-der2 input {
  box-sizing: border-box;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  font-style: italic;
  padding: 0.4rem;
}

.edPsi-td-der3 input {
  box-sizing: border-box;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  font-style: italic;
  padding: 0.4rem;
}


/********* RADIO BUTTOM **********/

.radio-container-edPsi label {
  font-size: 0.8rem;
  color: #494949;
  margin-right: 0.6rem;
}


/************************/


/*  ID Select */

#select-sexo-edPsi,
#select-escolaridad-edPsi,
#select-pref_sex-edPsi,
#select-edo-civil-edPsi,
#select-edo-edPsi,
#select-pais-edPsi {
  width: 100%;
  font-size: 0.8rem;
  padding: 0.2rem;
  outline: none;
  color: #494949;
  border-radius: 0.3rem;
}


#select-sexo-edPsi option,
#select-escolaridad-edPsi option,
#select-pref_sex-edPsi option,
#select-edo-civil-edPsi option,
#select-edo-edPsi option,
#select-pais-edPsi option {
  font-size: 0.8rem;
  color: #000000;
}

/* ----------------- */


/* Div botones: ENVIAR CANCELAR*/

/*   div Padre   autoreg y botones   */

.div-autoregistro-botones-edPsi {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 2rem;
  /* border: 1px solid blue; */
}

.div-btn-alineacion-edPsi {
  box-sizing: border-box;
  margin: auto;
  width: 20%;
  display: flex;
  /* border: 1px solid blue; */
}


/* Div botones: ENVIAR CANCELAR*/
.div-edPsi-enviar {
  box-sizing: border-box;
  width: 50%;
  margin: auto;
  /* border: 1px solid rgb(5, 101, 165); */
}

.div-edPsi-cancelar {
  box-sizing: border-box;
  width: 50%;
  margin: auto;
  /* border: 1px solid rgb(5, 101, 165); */
}


#btn-enviar-edPsi,
#btn-cancelar-edPsi {
  border-radius: 0.4rem;
  border: 1px solid gray;
  cursor: pointer;
}

/*----------------------------------------*/









