


#img_profile {
    width: 20%;
}

#lbl_menuUsers {
    font-size: 0.8rem;
    color: aliceblue;
}

#lbl_menuUsers_nombre {
    font-size: 0.8rem;
    color: aliceblue;
    padding-left: 0.4rem;
}

#account_menu {
    cursor: pointer;
}


/*------------------------------------*/
/*  ----- Menú Desplegable CSS ------  */


/*   Cambiar menú a vertical  */

.navbar-left .nav-links #ul_profile {
    display: none;
    /*ocultar menú */
    position: absolute;
    top: 100%;
    padding: 0;
}

/*   Items   */
.navbar-left .nav-links #ul_profile li {
    background-color: #752e8b;
    float: none;
    /*convierte a vertical*/
    padding: 0.3rem;
    width: 180%;
    font-size: 0.8rem;
}

/*    Enlaces    */
.navbar-left .nav-links #ul_profile a {
    /*line-height:120%;*/
    text-align: left;
    padding-left: 0.3rem;
}

/* .navbar-left .nav-links #ul_profile i {
    background-color: #ffbc0e;
} */

/* items del menu desplegable 
al pasar el ratón */

.navbar-left .nav-links li:hover>#ul_profile {
    z-index: 100;
    display: block;
}

