/*---- TRANSFERENCIAS -----*/



#encabezado_datos_transfer th:first-child {
    width: 75%;
    font-size: 0.8rem;
    text-align: left;
    padding-left: 0.4rem;
    /* border: 1px solid red; */
}

#encabezado_datos_transfer th:nth-child(2) {
    width: 25%;
    font-size: 0.8rem;
    text-align: left;
    /* border: 1px solid red; */
}

.div-headMotivo-transfer {
    width: 100%;
    margin-top: 0.4rem;
    margin-bottom: 1rem;
    /* border: 1px solid red; */

}


.div-3-Transfer-menu {
    box-sizing: border-box;
    margin-left: 1rem;
    width: 60%;
    padding: 0.4rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
    box-shadow: 2px 2px 3px 0;
  }



  #filas_px_transfer {
    width: 95;
    /* border: 1px solid red; */
  }
  
  
  #lbl-motivo-transfer {
    font-size: 0.8rem;
    padding: 0.4rem;
  }



