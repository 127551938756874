/************** CITAS **************/


.div-1-citas {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}


/***************************/

.px_div_2_citas {
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  margin-bottom: 2rem;
  /* border: 1px solid rgb(12, 22, 161); */
}

.px_sesion_citas {
  box-sizing: border-box;
  width: 30%;
  display: flex;
  align-items: center;
  /* border: 1px solid blue; */
}

.px_sesion_citas #lbl-enSesion {
  font-weight: 600;
}

.div_lbl_citas {
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  /* border: 1px solid green; */
}

/**************************/

/* ------div BUSCADOR ------ */

.px_buscador_citas {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  /* border: 1px solid black; */
}


.px_labelPx_citas {
  width: 25%;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid rgb(162, 158, 202);
}

.px_labelPx_citas label {
  padding: 0.4rem;
  font-size: 1rem;
  font-weight: 600;
}

/******* Input Buscador ********/

.input_buscar_cita {
  box-sizing: border-box;
  position: relative;
  width: 25%;
  padding-left: 0.4rem;
  margin-right: 2rem;
  /* border: 1px solid red; */
}

/*************************/


/******** filtro **********/

/*---------- Selec filtro ---------- */
.px_filtro_cita {
  box-sizing: border-box;
  width: 50%;
  display: flex;
  align-items: center;
  /* border: 1px solid blue; */
}

#px_select_cita {
  width: 35%;
  padding: 0.4rem;
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  outline: none;
  vertical-align: middle;
  color: #5e5e5e;
  /* border: 1px solid rgb(255, 0, 191); */
}

#px_select_cita option {
  font-size: 0.8rem;
  color: #363636;
}


/* btn limpiar filtro*/
#btn-limpiar-filtro {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 1.6rem;
  border: none;
  border-radius: 5px;
  color: aliceblue;
  font-size: 0.8rem;
  margin-left: 1rem;
  cursor: pointer;
  background-color: blueviolet;
}

#btn-limpiar-filtro img {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
}


/********** Div 3 ****************/

.px_div_3_citas {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  /* border: 1px solid rgb(161, 12, 141); */
}


.div-displayPx-cita {
  box-sizing: border-box;
  width: 25%;
  padding: 0.4rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
  box-shadow: 2px 2px 3px 0;
}


#table_filas_px-citas {
  box-sizing: border-box;
  width: 100%;
}


.td-border-button button {
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
  padding: 0.4rem;
  padding-left: 0.8rem;
  border-radius: 0.3rem;
  border: none;
  margin-bottom: 0.4rem;
  cursor: pointer;
}


/* Contenedor Expediente: Details-Summary */
.div-3-citas-menu {
  box-sizing: border-box;
  margin-left: 1rem;
  width: 60%;
  padding: 0.4rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
  box-shadow: 2px 2px 3px 0;
}

/*------------*/

/***********************/

.div-psi-sesion {
  box-sizing: border-box;
  width: 100%;
  margin-top: 1rem;
  /* border: 1px solid green; */
}

.div-psi-sesion p {
  text-align: left;
  padding-left: 1rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  font-size: 0.8rem;
  font-weight: 600;
}





/*******************************/

.div-selec-px-citas {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  /* border: 1px solid green; */
}

#label-selec-px-citas {
  font-size: 0.8rem;
}


/***** Tabla head datos Px  *******/

.div-p-nombre-cita {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  /* border: 1px solid rgb(0, 255, 21); */
}

.div-avatar-Cita {
  box-sizing: border-box;
  width: 30%;
  margin-right: 0.2rem;
  padding: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
  border: 1px solid gray;
}

.div-table-head-cita {
  box-sizing: border-box;
  width: 70%;
  border-radius: 0.3rem;
  border: 1px solid gray;
}

/*  Tabla   */

.table-head-px-cita {
  box-sizing: border-box;
  width: 100%;
  /* border: 1px solid red; */
}

/********* Avatar **************/
.td-avatar-citas {
  text-align: center;
}

.lbl-avatar-citas img {
  width: 50%;
  height: 35%;
}

/********* Label Paciente  ***********/

#td-paciente-citas {
  font-size: 1.6rem;
  text-align: left;
  padding: 0.8rem;
  font-weight: 600;
}

#td-paciente-citas a {
  text-decoration: none;
}


/*     Tabla Datos paciente     */
/* Encabezado <th> */
/* Espacios intermedios en el th */

#encabezado_px_citas {
  width: 100%;
  /* border-collapse: collapse; */
  text-align: left;
  font-weight: 600;
  padding: 0.4rem;
  border-radius: 0.3rem;
  /* border: 1px solid red; */
  background-color: rgb(253, 218, 176);
}

#encabezado_px_citas th:first-child {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;

}

#encabezado_px_citas th:nth-child(2) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}

#encabezado_px_citas th:nth-child(3) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}

#encabezado_px_citas th:nth-child(4) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}

#encabezado_px_citas th:nth-child(5) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}

/*-------------------------*/

/* Espacio entre celdas de la fila */

#filas_px_citas td:first-child {
  width: 15%;
  text-align: center;
}

#filas_px_citas td:nth-child(2) {
  width: 15%;
  text-align: center;
}

#filas_px_citas td:nth-child(3) {
  width: 15%;
  text-align: center;
}

#filas_px_citas td:nth-child(4) {
  width: 15%;
  text-align: center;
}

#filas_px_citas td:nth-child(5) {
  width: 15%;
  text-align: center;
}


#lbl-edad-citas,
#lbl-trabajo-citas,
#lbl-edo-civil-citas,
#lbl-ciudad-citas,
#lbl-tel-citas {
  font-size: 0.8rem;
}



/*     Tabla Datos Cita del px     */
/********* Encabezado datos cita ********/

.div_table_display_citas {
  box-sizing: border-box;
  width: 100%;
  margin-top: 0.6rem;
  /* border: 1px solid green; */
}

.div_tableEncabezado_citas {
  box-sizing: border-box;
  width: 100%;
  padding: 0.4rem;
  border-radius: 0.3rem;
  background-color: rgb(253, 218, 176);
  border: 1px solid gray;
}

/* Tabla de encabezado */
#table_head_datos_cita {
  width: 100%;
  /* border: 1px solid rgb(0, 2, 128); */
}

#encabezado_datos_citas {
  width: 100%;
  text-align: left;
  font-weight: 600;
}

.div_tableFilas_citas {
  box-sizing: border-box;
  width: 100%;
  margin-top: 0.2rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
}

#encabezado_datos_citas th:first-child {
  width: 10%;
  font-size: 0.8rem;
  text-align: left;
  padding-left: 0.4rem;
  /* border: 1px solid red; */
}

#encabezado_datos_citas th:nth-child(2) {
  width: 20%;
  font-size: 0.8rem;
  text-align: left;
  /* border: 1px solid red; */
}

#encabezado_datos_citas th:nth-child(3) {
  width: 20%;
  font-size: 0.8rem;
  text-align: left;
  /* border: 1px solid red; */
}

#encabezado_datos_citas th:nth-child(4) {
  width: 15%;
  font-size: 0.8rem;
  text-align: left;
  /* border: 1px solid red; */
}

#encabezado_datos_citas th:nth-child(5) {
  width: 20%;
  font-size: 0.8rem;
  text-align: left;
  /* border: 1px solid red; */
}

#encabezado_datos_citas th:nth-child(6) {
  width: 20%;
  font-size: 0.8rem;
  text-align: left;
  /* border: 1px solid red; */
}

/*-------------------------*/

/* Espacio entre celdas de la fila */

#table_datos_cita {
  width: 100%;
}

#filas_datos_citas td:first-child {
  width: 10%;
  font-size: 0.8rem;
  padding: 0.4rem;
  text-align: center;
  /* border: 1px solid rgb(0, 2, 128) */
}

#filas_datos_citas td:nth-child(2) {
  width: 20%;
  font-size: 0.8rem;
  text-align: left;
  /* border: 1px solid rgb(0, 2, 128) */
}

#filas_datos_citas td:nth-child(3) {
  width: 20%;
  font-size: 0.8rem;
  text-align: left;
  /* border: 1px solid rgb(0, 2, 128) */
}

#filas_datos_citas td:nth-child(4) {
  width: 15%;
  font-size: 0.8rem;
  text-align: left;
  /* border: 1px solid rgb(0, 2, 128) */
}

#filas_datos_citas td:nth-child(5) {
  width: 20%;
  font-size: 0.8rem;
  text-align: left;
  /* border: 1px solid rgb(0, 2, 128) */
}

#filas_datos_citas td:nth-child(6) {
  width: 20%;
  font-size: 0.8rem;
  text-align: left;
  /* border: 1px solid rgb(0, 2, 128) */
}


/******************************************/


/******************************************/

.div-label-selec-cita {
  box-sizing: border-box;
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid rgb(0, 255, 21); */
}


.div-label-selec-cita p {
  font-size: 0.8rem;
}