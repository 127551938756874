

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;800&display=swap');

/* 
Está en index.css

.header_login {

} 

*/

.logo_login {
    width: 30%;
    height: 100%;
}

.logo_login img {
    height: 90%;
    width: 100%;
    cursor: pointer;
    padding-left: 1.1rem;
    transition: all 0.3s;
}

.logo_login img:hover{
    transform: scale(1.2);
}


/* LISTA*/

.lista_login {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
    position: relative;
}

.links_login {
    list-style: none;
    position: absolute;
    top: 50%;
    margin-top: -12px;
}

.links_login li{
    display: inline-block;
    padding: 0 20px;
    justify-content: space-between;
}

.links_login li:hover{
    transform: scale(1.1);
}

.links_login a{
    font-size: 1rem;
    color: #eceff1;
    text-decoration: none;    
}

.links_login li a:hover{
    color: #ffbc0e;
}
