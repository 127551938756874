

.div-datos-hr {
    width: 50%;
    margin: auto;
    margin-bottom: 2rem;
    /* border: 1px solid red; */
}

.div-datos-hr label {
    font-weight: 600;
    /* border: 1px solid red; */
}




