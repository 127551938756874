/*---------------------------*/
/*  ---- PACIENTES ------- */
/*---------------------------*/


/* -- border-box -- para mantener el tamaño de caja 
aunque se aplique padding*/

/****** DIV PRINCIPAL ***********/
.px_div_1 {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 2rem;
  /* overflow: auto;
  overflow-y: auto; */
  /* border: 1px solid red; */
}

/********** En Sesión Psic **************/
/**********PACIENTES*****************/

.px_div_2 {
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  margin-bottom: 2rem;
  /* border: 1px solid rgb(12, 22, 161); */
}

.px_sesion {
  box-sizing: border-box;
  width: 30%;
  display: flex;
  align-items: center;
  /* border: 1px solid blue; */
}

.px_sesion #lbl-enSesion {
  font-weight: 600;
}

.div_lbl_px {
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  /* border: 1px solid green; */
}


/* ------div BUSCADOR ------ */

.px_buscador {
  box-sizing: border-box;
  width: 70%;
  display: flex;
  align-items: center;
  /* border: 1px solid black; */
}

/************************************/

/* input buscar */

.div_No_exp_buscar_px {
  box-sizing: border-box;
  width: 30%;
  /* border: 1px solid red; */
}

.input_buscar_px {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  border-radius: 0.3rem;
  /* border: 1px solid red; */
}

#Buscador {
  box-sizing: border-box;
  width: 100%;
  padding: 0.4rem;
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  outline: none;
  border-bottom: solid 1px #ccc;
}

#img_lupa {
  position: absolute;
  right: 0.8rem;
  top: 10%;
  /* transform: scaleY(-50%);
  transform: scaleX(-1); */
}

#Buscador::placeholder {
  font-size: 0.8rem;
}


/*---------- Selec filtro ---------- */
.px_filtro {
  box-sizing: border-box;
  width: 15%;
  /* border: 1px solid blue; */
}

.px_lb_filtro {
  font-size: 0.8rem;
  margin-right: 15px;
  vertical-align: middle;
  /* border: 1px solid rgb(9, 255, 0); */
}

#px_select {
  width: 30%;
  padding: 0.4rem;
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  outline: none;
  vertical-align: middle;
  color: #5e5e5e;
  /* border: 1px solid rgb(255, 0, 191); */
}

#px_select option {
  font-size: 0.8rem;
  color: #363636;
}


/* btn limpiar filtro*/

#btn-limpiar-filtro {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 1.6rem;
  border: none;
  border-radius: 5px;
  color: aliceblue;
  font-size: 0.8rem;
  margin-left: 1rem;
  cursor: pointer;
  background-color: blueviolet;
}

#btn-limpiar-filtro img {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
}

/*----- Pac inactivo radio ---------*/

.div-verPacInact {
  width: 40%;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
}

.div-verPacInac-label {
  width: 70%;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  /* border: 1px solid green; */
}

.div-verPacInac-radio {
  width: 30%;
  display: flex;
  align-items: center;
  /* border: 1px solid green; */
}

#id-verPacInac-No {
  width: 50%;
  /* border: 1px solid green; */
}

#id-verPacInac-No input {
  margin-left: 0.6rem;
}


/* boton paciente nuevo */
.px_nuevo {
  width: 15%;
  padding-right: 0.6rem;
  display: flex;
  justify-content: end;
  /* border: 1px solid green; */
}

#px_btn_nuevo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 1.6rem;
  font-size: 0.8rem;
  border: none;
  border-radius: 5px;
  color: aliceblue;
  margin-left: 1rem;
  cursor: pointer;
  background-color: blueviolet;
}

#px_btn_nuevo:hover {
  transform: scale(1.05);
  background-color: rgb(121, 0, 235);
}

#px_btn_nuevo img {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.3rem;
  /* border: 1px solid green; */
}




/* ------------------------------ */
/* Listado de pacientes */

.px_div_3 {
  box-sizing: border-box;
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  /* border: 1px solid rgb(0, 47, 255); */
}

.div_px_lista {
  box-sizing: border-box;
  width: 70%;
  height: 100%;
  overflow: auto;
  overflow-y: auto;
  margin-right: 1rem;
  /* border: 1px solid red; */
}


/* ------------------------------ */

/*  DIV PACIENTE SESIONES EXP CITAS NOTIF  */

.div_tablas_th_px-a {
  box-sizing: border-box;
  width: 100%;
  /* border: 1px solid red; */
}

.div_tablas_th_px-b {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0.3rem;
  border: 1px solid gray;
  background-color: rgb(255, 221, 180);
}

.div_tablas_th_px-c {
  box-sizing: border-box;
  width: 100%;
  margin-top: 0.2rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
  background-color: rgb(255, 221, 180);
}



/*   pacientes activos    */

.tr-encabezado-px {
  width: 100%;
  /* border: 1px solid green; */
}

.tr-encabezado-px th {
  padding: 0.8rem;
  text-align: center;
}

/*   pacientes Inactivos    */

.tr-encabezado-Inpx {
  width: 100%;
  /* border: 1px solid green; */
}

.tr-encabezado-Inpx th {
  padding: 0.8rem;
  text-align: center;
}

.div-pacActivos-px {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 0.4rem;
  border-radius: 0.3rem;
  border: 1px solid rgb(255, 197, 122);
  background-color: rgb(255, 242, 225);
}

.div-pacActivos-px label {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.8rem;
}


.div-pacInactivos-px {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 0.4rem;
  margin-top: 1.2rem;
  border-radius: 0.3rem;
  border: 1px solid rgb(255, 197, 122);
  background-color: rgb(255, 242, 225);
}

.div-pacInactivos-px label {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.8rem;
}


/* TABLAS: ENCABEZADO Y FILAS PX*/

/* filas <td> tabla px del buscador  */

.div_tablas_th_px {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-y: auto;
  /* border: 1px solid purple; */
}

#displayData_px {
  margin-top: 10px;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

/* Encabezado <th> */
/* Espacios intermedios en el th */

/*   Pacientes Activos    */

#encabezado_px {
  box-sizing: border-box;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-weight: 600;
}

#encabezado_px th:first-child {
  width: 20%;
  text-align: left;
  font-size: 0.8rem;
}

#encabezado_px th:nth-child(2) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}

#encabezado_px th:nth-child(3) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}

#encabezado_px th:nth-child(4) {
  width: 20%;
  font-size: 0.8rem;
  text-align: center;
}

#encabezado_px th:nth-child(5) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}

#encabezado_px th:nth-child(6) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}

/*-------------------------*/

/* Espacio entre celdas de la fila */

#displayData_px td:first-child {
  width: 20%;
  padding-left: 0.6rem;
  text-align: left;
}

#displayData_px td:nth-child(2) {
  width: 15%;
}

#displayData_px td:nth-child(3) {
  width: 15%;
}

#displayData_px td:nth-child(4) {
  width: 20%;
}

#displayData_px td:nth-child(5) {
  width: 15%;
}

#displayData_px td:nth-child(6) {
  width: 15%;
}






/******  TABLA PX  ****************/

.px_tabla {
  box-sizing: border-box;
  width: 30%;
  /* height: 100%; */
  padding: 0.4rem;
  border-radius: 0.3rem;
  /* border: 1px solid red; */
}

/* .div-edit-delete-px {
  border: 1px solid red;
} */

#edit_delete {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid rgb(162, 158, 202);
}

#edit_delete-Inactivo {
  box-sizing: border-box;
  width: 100%;
  /* height: 15%; */
  display: flex;
  border-radius: 0.3rem;
  background-color: rgb(230, 204, 204);
  /* border: 1px solid red; */
}

#px_h3 {
  box-sizing: border-box;
  width: 50%;
  display: flex;
  text-align: center;
  justify-content: cen;
  padding: 0.5rem;
  padding-left: 1rem;
  /* border: 1px solid blue; */
}

#lbl_datos_px {
  font-size: 1.2rem;
  font-weight: 600;
}


/* Div tabla sin datos */

.div-selec-px {
  width: 100%;
  /* height: 30%; */
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}


#label-selec-px-px {
  font-size: 0.8rem;
}



/*   ICONOS   */

.material-symbols-outlined {
  font-size: 1.2rem;
  color: aliceblue;
  padding-right: 5px;
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}

#img_filter,
#img_menu,
#img_edit,
#img_delete {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 10px;
  margin-top: 10px;
}

#img_filter,
#img_menu,
#img_delete,
#img_edit {
  cursor: pointer;
}

/*-----------------------------*/



/* -- DIV Tabla Paciente seleccionado -- */
/* agregando scroll */

.px_seleccionado_tabla {
  box-sizing: border-box;
  width: 100%;
  /* height: 160%; */
  /* overflow: auto;
	overflow-y: auto; */
  /* border: 1px solid red;
  background-color: aqua; */
}


/********** ToolTip *************/

.div-px-toolTip {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  /* border: 1px solid red; */
}

#container-toolTip-editar-px {
  box-sizing: border-box;
  width: 20%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  /* border: 1px solid rgb(0, 102, 255); */
}

.lbl-toolTip-editar-px {
  visibility: hidden;
  box-sizing: border-box;
  position: absolute;
  visibility: visible;
  font-size: 0.8rem;
  background-color: #5e5e5e;
  color: #fff;
  border-radius: 4px;
  padding: 0.2rem;
  z-index: 10;
  bottom: 100%;
  /* Posiciona el tooltip encima del elemento */
  transform: translateX(-20%);
  opacity: 0;
  transition: opacity 0.3s;
  /* border: 1px solid rgb(0, 255, 34); */
}


#container-toolTip-delete-px {
  box-sizing: border-box;
  width: 20%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  /* border: 1px solid rgb(0, 102, 255); */
}

.lbl-toolTip-delete-px {
  visibility: hidden;
  box-sizing: border-box;
  position: absolute;
  visibility: visible;
  font-size: 0.8rem;
  background-color: #5e5e5e;
  color: #fff;
  border-radius: 4px;
  padding: 0.2rem;
  z-index: 10;
  bottom: 100%;
  /* Posiciona el tooltip encima del elemento */
  transform: translateX(-20%);
  opacity: 0;
  transition: opacity 0.3s;
}


#container-toolTip-activar-px {
  box-sizing: border-box;
  width: 20%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 0.6rem;
  /* border: 1px solid rgb(0, 102, 255); */
}

.lbl-toolTip-activar-px {
  visibility: hidden;
  box-sizing: border-box;
  position: absolute;
  visibility: visible;
  font-size: 0.8rem;
  background-color: #5e5e5e;
  color: #fff;
  border-radius: 4px;
  padding: 0.2rem;
  z-index: 10;
  bottom: 100%;
  /* Posiciona el tooltip encima del elemento */
  transform: translateX(-20%);
  opacity: 0;
  transition: opacity 0.3s;
  /* border: 1px solid rgb(0, 255, 34); */
}

/* regla CSS para mostrar el tooltip cuando se pasa 
el cursor sobre el elemento */

#container-toolTip-editar-px:hover .lbl-toolTip-editar-px {
  visibility: visible;
  opacity: 1;
}

#container-toolTip-delete-px:hover .lbl-toolTip-delete-px {
  visibility: visible;
  opacity: 1;
}

#container-toolTip-activar-px:hover .lbl-toolTip-activar-px {
  visibility: visible;
  opacity: 1;
}


/*********************************/



/* -- Tabla datos del Paciente seleccionado -- */

#table_px_seleccionado {
  box-sizing: border-box;
  width: 100%;
  /* height: 50%; */
  /* border: 1px solid rgb(0, 255, 0); */
}

.div-px_seleccionado-inactivo {
  border: 1px solid gray;
  color: rgb(112, 112, 112);
  border-radius: 0.3rem;
  background-color: #ffebd0;
}

#table_px_seleccionado-inactivo {
  box-sizing: border-box;
  width: 100%;
}

/*
  className="padding-row"
  Está en css Calendario
*/


/* MODALES */
div:where(.swal2-icon) {
  user-select: none;
}



/*   ------- TABLA Paciente del buscador --------  */

.table-line-bottom {
  border-bottom: 1px solid rgb(156, 156, 156);
}



/* dentro de const DisplayData */


#lbl_nom_px button {
  font-size: 0.8rem;
  font-weight: 600;
}

#lbl_estatus {
  font-size: 0.8rem;
}

#td_2,
#td_3,
#td_4,
#td_5,
#td_6 {
  padding: 0.6rem;
  text-align: center;
}

#img_psy,
#img_exped,
#img_groups {
  width: 1.8rem;
  height: 1.8rem;
}

#img_done {
  width: 1.2rem;
  height: 1.2rem;
}


/*   boton Transferir   */
#btn-transfer {
  border: none;
  background-color: rgb(255, 221, 180);
}


/* Botón Paciente lista */

#btn_liga_px {
  text-decoration: none;
  color: black;
  border-style: none;
  background: none;
  cursor: pointer;
}



/****** AVATAR *********/

.div-avatar-nombre-px {
  box-sizing: border-box;
  width: 100%;
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
  background-color: rgb(255, 220, 176);
  border: 1px solid gray;
}

.div-avatarPx {
  box-sizing: border-box;
  width: 50%;
  /* border: 1px solid rgb(0, 255, 0); */
}

#img_avatar_px {
  box-sizing: border-box;
  width: 80%;
  text-align: center;
  margin-top: 0.4rem;
  margin-bottom: 0.6rem;
  /* border: 1px solid rgb(255, 8, 0); */
}

#img_avatar_px img {
  box-sizing: border-box;
  width: 40%;
  height: 65%;
  margin: auto;
  border-radius: 0.2rem;
  box-shadow: 4px 4px 6px 0 #494949;
  /* border: 1px solid rgb(255, 0, 221); */
}


.div-nombrePx {
  box-sizing: border-box;
  width: 50%;
  /* border: 1px solid rgb(0, 255, 0); */
}



/* Tabla Paciente seleccionado: Calendario y Paciente */

.div-table_px_seleccionado {
  box-sizing: border-box;
  width: 100%;
  margin-top: 0.2rem;
  padding: 0.6rem;
  border-radius: 0.3rem;
  background-color: rgb(255, 220, 176);
  border: 1px solid gray;
}


.label-especialidad,
.label-cedula,
.label-foto,
.label-estatus,
.label-parentesco,
.label-familiar,
.label-psicologo,
.label-posgrado,
.label-autogenerado,
.label-contrasena,
.label-nom-usu,
.label-ciudad,
.label-paciente,
.label-edad,
.label-sexo,
.label-escolaridad,
.label-titulo,
.label-profesion,
.label-trabajo,
.label-pref_sex,
.label-edo-civil,
.label-tel,
.label-correo,
.label-domicilio,
.label-cp,
.label-edo,
.label-pais,
.label-comentario {
  padding-left: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
}

#lbl-especialidad,
#lbl-cedula,
#lbl-familiar,
#lbl-estatus,
#lbl-fecha,
#lbl-horaI,
#lbl-horaF,
#lbl-edad,
#lbl-escolaridad,
#lbl-profesion,
#lbl-titulo,
#lbl-trabajo,
#lbl-pref_sex,
#lbl-sexo,
#lbl-edo-civil,
#lbl-tel,
#lbl-correo,
#lbl-domicilio,
#lbl-ciudad,
#lbl-cp,
#lbl-edo,
#lbl-pais {
  padding-left: 1rem;
  font-size: 0.8rem;
}

#lbl-familiar a {
  font-size: 0.8rem;
  text-decoration: none;
}


#td-paciente {
  text-align: center;
  padding: 0.6rem;
}

#lbl-paciente {
  cursor: pointer;
  font-weight: 600;
}

/*---------------------------*/