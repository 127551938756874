



.div-notif-psi {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.div-notif-px {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
}

.p-notif-px {
    border: none;
    text-align: right;
    padding: 0;
    margin: 0;
}

.p-notif-px p {
    text-align: left;
    background-color: aquamarine;
}

.div-notif-psi p {
    text-align: right;
    border-left: none;
    border-right: 2px solid #364f6b;
    background-color: rgb(205, 198, 255);
}

.summary-notificaciones {
    width: 100%;
    display: flex;
}

.summary-mns-textarea {
    width: 85%;
}

#textarea-notificaciones {
    width: 100%;
    padding: 0.3rem 0.4rem 0.3rem 0.4rem;
}

.summary-mns-img {
    width: 10%;
    display: flex;
    align-items: center;
}

.summary-mns-img img {
    cursor: pointer;
}




.div-selec-px-notif {
    width: 100%;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    /* border: 1px solid green; */
}

#label-selec-px-notif {
    font-size: 0.8rem;
}



