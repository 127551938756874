

/* 
FOOTER

*/

.div-footer {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
    background-color: #66007a;
    /* border: 1px solid red; */
}

.div-footer-lbl {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    /* border: 1px solid red; */
    
}

.footer-lbl-contacto {
    color: aliceblue;
}


/* LISTA*/

.lista_footer {
    box-sizing: border-box;
    margin-bottom: 1rem;
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: center;
    /* border: 1px solid red; */
}

.links_footer li{
    display: inline-block;
    padding-right: 1rem;
}

.links_footer a{
    font-size: 0.8rem;
    color: #eceff1;
    text-decoration: none;    
}

.links_footer a img{
    width: 0.8rem;
    height: 0.8rem;
}


.links_footer li:hover{
    transform: scale(1.1);
}

.links_footer li a:hover{
    color: #ffbc0e;
}




