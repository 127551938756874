/*    EXPEDIENTES     */


.div-1-expedientes {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* overflow: auto;
  overflow-y: auto; */
  /* border: 1px solid red; */
  background-color: bisque;
}


.px_div_2_exped {
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(161, 12, 141); */
}

.px_sesion_exped {
  box-sizing: border-box;
  width: 100%;
  height: 40%;
  /* border: 1px solid blue; */
}

.px_sesion_exped p {
  font-size: 1rem;
  text-align: left;
  font-weight: 600;
}

.px_h1_exped {
  box-sizing: border-box;
  width: 100%;
  height: 60%;
  /* border: 1px solid green; */
}


.px_h1_exped h1 {
  text-align: center;
  margin: auto;
  font-size: 2rem;
}



/*----------------------------------*/

.div-2-No-expediente-buscar {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  /* border: 1px solid rgb(255, 0, 255); */
}

/***********************************/

/* ------div BUSCADOR ------ */

.px_buscador_exp {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
  /* border: 1px solid black; */
}


/******* Input Buscador ********/

.div_No_exp_buscar_exp {
  box-sizing: border-box;
  width: 30%;
  display: flex;
  /* border: 1px solid rgb(0, 255, 13); */
}

.input_buscar_exp {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  /* border: 1px solid red; */
}

.input_buscar_exp input {
  border-bottom: 1px solid gray;
}

/******** filtro **********/

/*---------- Selec filtro ---------- */
.px_filtro_exp {
  box-sizing: border-box;
  width: 50%;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  /* border: 1px solid blue; */
}

#px_select_exp {
  width: 40%;
  padding: 0.4rem;
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  outline: none;
  vertical-align: middle;
  color: #5e5e5e;
  /* border: 1px solid rgb(255, 0, 191); */
}

#px_select_exp option {
  font-size: 0.8rem;
  color: #363636;
}

/***************************/


/************************************/

.px_div_3_exp {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  /* border: 1px solid rgb(161, 12, 141); */
}


.px_div_3_exp_lbl {
  box-sizing: border-box;
  width: 30%;
  padding: 0.6rem;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.4rem;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid rgb(162, 158, 202);
}


/******* DisplayData **********/

/* tabla lista px buscado */
.div_tablas_th_px-exp {
  box-sizing: border-box;
  width: 30%;
  height: 100%;
  border-radius: 0.3rem;
  border: 1px solid rgb(162, 158, 202);
  box-shadow: 2px 2px 3px 0;
}

#filas_px-exp {
  width: 80%;
  overflow: auto;
  overflow-y: auto;
}


/*   DISPLAY PACIENTES   */

.td-borderBottom-Exp {
  box-sizing: border-box;
  border-bottom: 1px solid gray;
}

.td-borderBottom-Exp button {
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.8rem;
  border: none;
  background: none;
  cursor: pointer;
}



/*****************************/



/*  No. Exp Nombre px */

.div-no-expediente {
  width: 10%;
  margin-left: 1rem;
  /* background-color: aquamarine; */
}

.div-no-expediente input {
  width: 50%;
  height: 1.2rem;
  margin-left: 0.6rem;
}

/* No. Expd */

.div-no-expediente label {
  font-size: 0.8rem;
}

.div-no-expediente input {
  vertical-align: middle;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 0.8rem;
  outline: none;
  border-radius: 5px;
  padding: 0.2rem;
}


/* btn Imprimir*/

#btn-imprimir {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 1.6rem;
  font-size: 0.8rem;
  border: none;
  border-radius: 5px;
  color: aliceblue;
  margin-left: 1rem;
  cursor: pointer;
  background-color: blueviolet;
}

#btn-imprimir img {
  width: 1rem;
  height: 1rem;
  margin-right: 0.2rem;
}



/************* Buscador Px **********/

.input_buscar_exped {
  box-sizing: border-box;
  position: relative;
  width: 25%;
  margin-right: 2rem;
  /* padding: 0.2rem; */
  /* border: 1px solid red; */
}

.input_buscar_exped input {
  border-bottom: solid 1px #ccc;
}



/*-----------------------*/

.div-label-selec-cita p {
  margin: 1rem;
}





/*--------------------------------*/
/*--------------------------------*/
/*  Contenedor List Px, Exped, Info Px  */
/*--------------------------------*/
/*---------------------------------*/

.divExp-listPx-Expediente-infoPx {
  box-sizing: border-box;
  padding-top: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  /* border: 1px solid purple; */
}

/* Div Lista de Px buscados */
.div_px_lista-Exp {
  box-sizing: border-box;
  width: 20%;
  height: 100%;
  margin-right: 0.5rem;
  /* border: 1px solid rgb(44, 23, 235); */
}

/* tabla lista px buscado */
.div_tablas_th_px-Exp {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  /* border: 1px solid purple; */
}

#filas_px-Exp {
  width: 100%;
  overflow: auto;
  overflow-y: auto;
}


/* Contenedor Expediente: Details-Summary */
.div-3-expediente-menu {
  box-sizing: border-box;
  width: 60%;
  margin-left: 1rem;
  padding: 0.4rem;
  border-radius: 0.3rem;
  border: 1px solid rgb(162, 158, 202);
  box-shadow: 2px 2px 3px 0;
}


/*  div contenedor de tabla   */

.div-p-nombre-Exp {
  box-sizing: border-box;
  width: 100%;
  /* border: 1px solid rgb(243, 48, 210); */
}

/* tabla datos px  */

.table-head-px-Exp {
  width: 100%;
  /* border: 1px solid rgb(61, 48, 243); */
}

.table-head-px-Exp tr {
  /* border: 1px solid rgb(55, 243, 48); */
}

.table-head-px-Exp td {
  /* border: 1px solid rgb(243, 48, 48); */
}

/********* Avatar **************/
.td-avatar-Exp {
  text-align: center;
}

.div-avatar-Exp {
  box-sizing: border-box;
  width: 100%;
  /* border: 1px solid rgb(237, 48, 243); */
}

#img_avatar_Exp img {
  box-sizing: border-box;
  width: 5rem;
  border-radius: 0.2rem;
  box-shadow: 4px 4px 6px 0 #494949;
}


/*-----  Encabezado y Celdas Tabla -----*/

#encabezado_px_Exp {
  width: 100%;
  text-align: left;
  font-weight: 600;
  /* border: 1px solid red; */
  background-color: rgb(253, 218, 176);
}

#encabezado_px_Exp th:first-child {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;

}

#encabezado_px_Exp th:nth-child(2) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}

#encabezado_px_Exp th:nth-child(3) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}

#encabezado_px_Exp th:nth-child(4) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}

#encabezado_px_Exp th:nth-child(5) {
  width: 15%;
  font-size: 0.8rem;
  text-align: center;
}

/*-------------------------*/

/* Espacio entre celdas de la fila */

#filas_px_Exp td:first-child {
  width: 15%;
  text-align: center;
}

#filas_px_Exp td:nth-child(2) {
  width: 15%;
  text-align: center;
}

#filas_px_Exp td:nth-child(3) {
  width: 15%;
  text-align: center;
}

#filas_px_Exp td:nth-child(4) {
  width: 15%;
  text-align: center;
}

#filas_px_Exp td:nth-child(5) {
  width: 15%;
  text-align: center;
}


/********* Label Paciente  ***********/

#td-paciente-Exp {
  font-size: 1.6rem;
  text-align: left;
  padding: 0.8rem;
  font-weight: 600;
}

#td-paciente-Exp a {
  text-decoration: none;
}


#lbl-edad-Exp,
#lbl-trabajo-Exp,
#lbl-edo-civil-Exp,
#lbl-ciudad-Exp,
#lbl-tel-Exp {
  font-size: 0.8rem;
}


/*------------------------*/


/* menu desplegable flecha */
/*-----------------------*/
/* https://www.silocreativo.com/details-summary-elementos-desplegables-ocultos-html/ */


.faq-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin-top: 0.4rem;
  overflow: auto;
  overflow-y: auto;
  /* border: 1px solid red; */
}

details {
  box-sizing: border-box;
  background-color: #f6f8fa;
  width: 100%;
  margin-bottom: 0.6rem;
  border-radius: 5px;
  border: 1px solid #d8e0e9;
  color: #364f6b;
  position: relative;
}

details summary {
  box-sizing: border-box;
  font-weight: 600;
  font-size: 0.8rem;
  padding: 0.6rem;
  cursor: pointer;
  list-style: none;
}

details label {
  padding: 0.6rem;
  /* margin: 1rem;
  text-align: left;
  font-size: 0.8rem;
  background: #f6f8fa;
  border-left: 2px solid #364f6b; */
}


/*--------------------------*/
/* Parrafo de Sesiones */
/*-----------------------------*/

#parrafo-textarea {
  padding: 0.6rem;
  margin: 0 0.6rem 0.6rem 0.6rem;
  text-align: left;
  font-size: 0.8rem;
  background: #f6f8fa;
}

#textarea-sesiones {
  box-sizing: border-box;
  width: 100%;
  padding: 0.4rem;
  /* border: 1px solid red; */
}


#p-guardar-sesion {
  border: none;
  text-align: right;
}

#btn-guardar-sesion {
  padding: 0.3rem;
  border-radius: 0.4rem;
  border: 1px solid gray;
}

.div-selec-px-sesion {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}


/*--------------------*/

details:hover,
details[open] {
  box-shadow: 5px 5px 15px #d9d9d9;
}

details[open] {
  background: #ffffff;
}

details[open] summary {
  font-weight: 600;
}


/* Icono Details ⌵ > */
/*<detaila><summry>: https://www.espai.es/blog/2022/12/como-utilizar-la-etiqueta-summary-html/*/
/*simbolos: https://es.piliapp.com/symbol/#typographical*/

details summary::before {
  position: absolute;
  /*content: "👇";*/
  content: "⌵";
  font-size: 1rem;
  font-weight: 600;
  right: 5%;
}


details[open] summary::before {
  rotate: -90deg;
}



/*----------------------------*/

.summary-sesiones {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: 0.4rem;
  /* border: 1px solid red; */
}

.summary-sesiones-2 {
  width: 20%;
  display: flex;
  /* border: 1px solid salmon; */
}

.summary-sesiones-2 button {
  width: 5rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  border: 1px solid  rgb(212, 212, 212);
}

.summary-sesiones-2 label {
  padding: 0;
  cursor: pointer;
  font-size: 0.8rem;
  /* border: 1px solid red; */
}

.summary-sesiones-2 img {
  padding: 0;
  /* border: 1px solid salmon; */
}

#id-hr-exp {
  width: 95%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}


/*------ Table file exp -------*/

.div-tableFile-exp {
  width: 100%;
  padding: 0.6rem;
  /* border: 1px solid red; */
}

.div-tableFile2-exp {
  width: 100%;
  border-radius: 0.3rem;
  border: 1px solid gray;
}

#tableFile-exp {
  width: 100%;
}

#thead-file-exp {
  width: 100%;
  padding: 0.4rem;
  margin-bottom: 0.4rem;
  border-bottom: 1px solid gray;
}

#th-file-exp1 {
  width: 60%;
  padding-left: 0.3rem;
  font-size: 0.8rem;
  /* border: 1px solid blue; */
}

#th-file-exp2 {
  width: 20%;
  font-size: 0.8rem;
  text-align: center;
  /* border: 1px solid blue; */
}

#th-file-exp3 {
  width: 20%;
  font-size: 0.8rem;
  text-align: center;
  /* border: 1px solid blue; */
}

#tbody-file-exp {
  width: 100%;
  padding: 0.4rem;
}

#td-file-exp1 {
  padding-left: 0.3rem;
  font-size: 0.8rem;
}

#td-file-exp2 {
  font-size: 0.8rem;
  text-align: center;
}


.summary-sesiones-3 {
  width: 22%;
  display: flex;
  /* border: 1px solid salmon; */
}

.summary-sesiones-3 button {
  width: 6rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  border: 1px solid rgb(212, 212, 212);
}

.summary-sesiones-3 label {
  padding: 0;
  cursor: pointer;
  font-size: 0.8rem;
  /* border: 1px solid red; */
}

.summary-sesiones-3 img {
  padding: 0;
  /* border: 1px solid salmon; */
}


/*-----------------*/



#summary-edit {
  text-align: left;
  margin-bottom: 0.4rem;
  padding: 0;
  background-color: white;
  border-left: none;
  cursor: pointer;
}