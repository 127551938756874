

/* -------- EDITAR CITA --------*/



.div-editar-cita-modal {
  box-sizing: border-box;
  width: 100%;
  /* border: 1px solid red;*/
}


.div-editar-cita-modal table {
  width: 100%;
  border: 1px solid gray;
  /* border: 1px solid red; */
}


/* label derecha modal*/
.editar-cita-td-izq {
  width: 40%;
  /* background-color: aqua; */
  /* border-bottom: 1px solid #797979; */
}


/* label izquierda modal*/
.editar-cita-td-der {
  width: 50%;
  /* background-color: aqua; */
  /* border-bottom: 1px solid #797979; */
}


/*------- PX -----------*/
/*------ Labels --------*/

.lbl-psi-editar,
.lbl-fecha,
.lbl-horaI,
.lbl-horaF,
.lbl-comentario {
  font-size: 0.8rem;
  float: left;
  padding: 0.6rem;
  font-weight: 600;
}

/*------ Inputs --------*/

#input-fecha,
#input-horaI,
#input-horaF,
#input-comentario {
  width: 100%;
  font-size: 0.8rem;
  border: none;
  outline: none;
  background-color: transparent;
}

/*---------------*/

/*------- PSI -----------*/
/*------ Labels --------*/

.lbl-psi-editar,
.lbl-fecha-editar,
.lbl-horaI-editar,
.lbl-horaF-editar,
.lbl-comentario-editar {
  font-size: 0.8rem;
  float: left;
  padding: 0.6rem;
  font-weight: 600;
}

/*------ Inputs --------*/

#input-fecha-editar,
#input-horaI-editar,
#input-horaF-editar,
#input-comentario-editar {
  width: 100%;
  font-size: 0.8rem;
  border: none;
  outline: none;
  background-color: transparent;
}

/*---------------*/



/*  Select */
#select-px-editar {
  width: 100%;
  height: 100%;
  font-size: 0.8rem;
  border: none;
  outline: none;
}

#select-px-editar option {
  font-size: 0.8rem;
}

#select-psicologo {
  width: 100%;
  height: 100%;
  font-size: 0.8rem;
  border: none;
  outline: none;
}

#select-psicologo option {
  font-size: 0.8rem;
}



/* --- inputs -------------- */

.editar-cita-td-der input {
  box-sizing: border-box;
  width: 100%;
  padding: 0.6rem;
  font-size: 0.8rem;
  border: none;
  background-color: transparent;
  /* outline: none; */
}



