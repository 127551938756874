.div-1-sesiones {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    padding: 1rem;
    /* border: 1px solid red; */
}


.div-2-sesiones {
    box-sizing: border-box;
    width: 100%;
    flex-direction: column;
    margin-bottom: 1rem;
    /* border: 1px solid rgb(161, 12, 141); */
}


/*------ Buscador Px ----------*/
.div_buscador_px_sesiones {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    /* border: 1px solid rgb(106, 12, 161); */
}

.input_buscar_sesiones {
    box-sizing: border-box;
    width: 25%;
    position: relative;
    margin-right: 2rem;
    /* border: 1px solid rgb(12, 15, 161); */
}

.div_filtro_sesiones {
    box-sizing: border-box;
    width: 50%;
    display: flex;
    align-items: center;
    /* border: 1px solid rgb(7, 121, 228); */
}



/************ Div 3 *************/

.div-3-sesiones {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    /* border: 1px solid red; */
}

.div3_DisplayPx {
    width: 25%;
}

.div_DisplayPx_label {
    width: 25%;
    padding: 0.4rem;
    border-radius: 0.3rem;
    background-color: rgb(236, 216, 255);
    border: 1px solid rgb(162, 158, 202);
}

.div_DisplayPx_label label {
    font-size: 1rem;
    font-weight: 600;
}


/********* DisplayPx ************/

.div_DisplayPx_sesion {
    box-sizing: border-box;
    width: 100%;
    padding: 0.4rem;
    margin-top: 0.4rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
    box-shadow: 2px 2px 3px 0;
}


#table-DisplayPx-sesion {
    box-sizing: border-box;
    width: 100%;
    overflow: auto;
    overflow-y: auto;
}

.td-border-button button {
    box-sizing: border-box;
    width: 80%;
    text-align: left;
    font-size: 0.8rem;
    padding: 0.4rem;
    padding-left: 0.8rem;
    border-radius: 0.3rem;
    border: none;
    margin-bottom: 0.4rem;
    cursor: pointer;
}

/******** tabla datos pac sesion ---------*/

.div-p-nombre-sesion {
    display: flex;
    /* border: 1px solid blue; */
}

.div-avatar-sesion {
    width: 30%;
    /* border: 1px solid blue; */
}

#img_avatar_sesion {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid blue; */
}

#img_avatar_sesion img {
    width: 60%;
    border-radius: 0.3rem;
    border: 1px solid gray;
    box-shadow: 2px 2px 3px 0;
}

.table-head-px-sesion {
    width: 100%;
    /* border: 1px solid blue; */
}

/********* Desplegable **********/

.div-desplegable-sesion {
    box-sizing: border-box;
    margin-left: 1rem;
    margin-top: 0.4rem;
    width: 60%;
    padding: 0.4rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
    box-shadow: 2px 2px 3px 0;
}

.px_sesion_sesion {
    box-sizing: border-box;
    width: 100%;
    height: 40%;
    /* border: 1px solid blue; */
}

.px_sesion_sesion p {
    font-size: 1rem;
    text-align: left;
    font-weight: 600;
}

.px_h1_sesion {
    box-sizing: border-box;
    width: 100%;
    height: 60%;
    /* border: 1px solid green; */
}


.px_h1_sesion h1 {
    text-align: center;
    margin: auto;
    font-size: 2rem;
}


.div-selecPx-sesion {
    width: 100%;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    /* border: 1px solid green; */
}

#label-selecPx-sesion {
    font-size: 0.8rem;
}

#div-datos-sesiones {
    box-sizing: border-box;
    width: 100%;
    flex-direction: column;
    /* border: 1px solid green; */
}

#div-FechaSelect-sesiones {
    box-sizing: border-box;
    width: 100%;
    flex-direction: column;
    /* border: 1px solid rgb(128, 0, 100); */
}

#div-datos2-sesiones {
    box-sizing: border-box;
    width: 100%;
    /* border: 1px solid rgb(128, 0, 0); */
}

#div-datos3-sesiones {
    display: flex;
    align-items: center;
    /* border: 1px solid rgb(128, 0, 0); */
}

#div-datos4-sesiones {
    box-sizing: border-box;
    width: 40%;
    /* border: 1px solid rgb(128, 0, 0); */
}

#div-select-sesiones {
    box-sizing: border-box;
    width: 60%;
    text-align: end;
    /* border: 1px solid rgb(0, 66, 128); */
}


/******** Select estatus cita ********/

.select-estatus-sesiones {
    box-sizing: border-box;
    width: 50%;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-color: rgb(103, 150, 177);
    border-radius: 0.3rem;
    color: rgb(77, 77, 77);
}



/**********************************/
/************ FOTO ************/
/**********************************/

.div-content-photo-sesion {
    box-sizing: border-box;
    width: 100%;
    height: 20%;
    display: flex;
    /* border: 1px solid rgb(0, 255, 21); */
}


/***** icono Foto sin Datos *******/

.div-photo-sesion-sinDatos {
    box-sizing: border-box;
    width: 20%;
    height: 100%;
    /* border: 1px solid rgb(0, 255, 21); */
}

.div-photo-sesion-sinDatos img {
    box-sizing: border-box;
    width: 20%;
    height: 20%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid blue; */
}


/***** icono Foto con Datos *******/

.div-photo-sesion {
    box-sizing: border-box;
    width: 20%;
    height: 100%;
    /* border: 1px solid rgb(0, 255, 21); */
}

.div-photo-sesion img {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid blue; */
}


.div-photo-parrafo-sesion {
    box-sizing: border-box;
    width: 100%;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid rgb(158, 4, 4); */
}