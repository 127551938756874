

/* ------ ADD RECOMENDACIÓN  ----- */


.div-1-addRecom {
    width: 100%;
    /* border: 1px solid red; */
}

.div-2-addRecom {
    width: 100%;
    /* border: 1px solid rgb(1, 156, 97); */
}

.div_lbl_addRecom {
    width: 100%;
    text-align: center;
    margin-bottom: 1.4rem;
    /* border: 1px solid red; */
}

.div_lbl_addRecom label {
    font-size: 2rem;
    font-weight: 600;
}

.div-datos-addRecom {
    width: 60%;
    margin: auto;
    display: flex;
    padding: 0.6rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.3rem;
    background-color: blanchedalmond;
    border: 1px solid rgb(197, 197, 197);
}

#div_image_addRecom {
    box-sizing: border-box;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid rgb(184, 8, 8); */
}

#div_label_addRecom {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#div_label_addRecom label {
    font-size: 1.4rem;
    font-weight: 600;
}

.div_image_addRecom {
    width: 50%;
    height: 2rem;
    margin: auto;
    /* border: 1px solid red; */
}

.div-3-addRecom {
    width: 60%;
    margin: auto;
    /* border: 1px solid rgb(0, 17, 255); */
}

.table-addRecom {
    width: 100%;
    /* border: 1px solid rgb(247, 4, 4); */
}

.table-border-addRecom {
    padding: 0.6rem;
    border-radius: 0.3rem;
    background-color: blanchedalmond;
    border: 1px solid rgb(197, 197, 197);
}

#td-addRecom-izq {
    width: 20%;
    /* border: 1px solid red; */
}

#td-addRecom-izq label {
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.4rem;
}

#td-addRecom-der {
    width: 80%;
    text-align: left;
    /* border: 1px solid red; */
}

#td-addRecom-der input {
    font-size: 0.8rem;
    padding: 0.4rem;
    border-radius: 0.3rem;
    border: none;
}


.div-addRec-txtA {
    width: 100%;
    margin-top: 1.4rem;
}

.div-addRec-txtA textarea {
    box-sizing: border-box;
    width: 100%;
    font-size: 0.8rem;
    padding-top: 0.4rem;
    padding-bottom: 0.6rem;
    outline: none;
    padding-left: 0.4rem;
    border-radius: 0.3rem;
    /* resize: none;
    scrollbar-width: 0; /* para Firefox */
    line-height: 1rem;  /* interlineado */
    height: 150px;
    max-height: 300px;
    min-height: 100px;
}

.div-autoregistro-botones-addRecom {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: end;
    width: 60%;
    margin-top: 1rem;
    /* border: 1px solid red; */
}

.div-addRec-enviar button {
    font-size: 0.9rem;
    margin-right: 1rem;
}

.div-addRec-cancelar button {
    font-size: 0.9rem;
    margin-right: 1rem;
}






