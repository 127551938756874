/*---------------------------*/
/*  ---- ADMINISTRACION ------- */
/*---------------------------*/


/* -- border-box -- para mantener el tamaño de caja 
aunque se aplique padding*/


.px_div_1_admin {
    box-sizing: border-box;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    /* overflow: auto;
	overflow-y: auto; */
    /* border: 1px solid red; */
}

/***************************/

.px_div_2_admin {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    /* border: 1px solid rgb(12, 22, 161); */
}

.div_sesion_admin {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1.6rem;
    /* border: 1px solid rgb(12, 22, 161); */
}


.px_sesion_admin {
    box-sizing: border-box;
    width: 20%;
    /* border: 1px solid blue; */
}

.px_sesion_admin #lbl-enSesion {
    font-weight: 600;
}

.div_lbl_admin {
    width: 60%;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    /* border: 1px solid green; */
}


/************ TABLAS ************/

.tablas_administracion {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    /*justify-content: space-between; /*distribuye una separacion equitativa*/
    /* border: 1px solid red; */
}

/*Flex: none; para que se quede con el ancho/altura definido
   no anula la propiedad Flex del Padre */

.tablas_item_modulos {
    box-sizing: border-box;
    width: 45%;
    flex-direction: column;
    padding: 0.6rem;
    /*flex-wrap: wrap;  /*permite saltar a las siguiente linea*/
    border-radius: 3px;
    box-shadow: 2px 2px 3px 0;
    border: 1px solid gray;
}

.tabla_item {
    box-sizing: border-box;
    width: 100%;
    padding: 0.4rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    border-radius: 3px;
    box-shadow: 1px 1px 2px 0;
    border: 1px solid gray;
}

.tabla_item label {
    padding-bottom: 0.4rem;
    color: brown;
}

/* tabla pacientes */
#tabla_pacientes {
    box-sizing: border-box;
    width: 100%;
    /* border: 1px solid red; */
}

#tabla_pacientes tr td {
    box-sizing: border-box;
    width: 100%;
    /* border: 1px solid red; */
}

#tabla_pacientes td:first-child {
    width: 20%;
}

#tabla_pacientes td:nth-child(2) {
    width: 10%;
    text-align: center;
}

#tabla_pacientes td:nth-child(3) {
    width: 40%;
    text-align: center;
}



/* tabla psicologos */

#tabla_psicologos {
    box-sizing: border-box;
    width: 100%;
    /* border: 1px solid red; */
}

#tabla_psicologos tr td {
    box-sizing: border-box;
    width: 100%;
    /* border: 1px solid red; */
}

#tabla_psicologos td:first-child {
    width: 20%;
}

#tabla_psicologos td:nth-child(2) {
    width: 10%;
    text-align: center;
}

#tabla_psicologos td:nth-child(3) {
    width: 40%;
    text-align: center;
}




/* tabla colegas */

#tabla_colegas {
    box-sizing: border-box;
    width: 100%;
    /* border: 1px solid red; */
}

#tabla_colegas tr td {
    box-sizing: border-box;
    width: 100%;
    /* border: 1px solid red; */
}

#tabla_colegas td:first-child {
    width: 20%;
}

#tabla_colegas td:nth-child(2) {
    width: 10%;
    text-align: center;
}

#tabla_colegas td:nth-child(3) {
    width: 40%;
    text-align: center;
}


/* tabla citas */

#tabla_citas {
    box-sizing: border-box;
    width: 100%;
    /* border: 1px solid red; */
}

#tabla_citas tr {
    box-sizing: border-box;
    width: 100%;
    /* border: 1px solid red; */
}

#tabla_citas td:first-child {
    width: 20%;
}

#tabla_citas td:nth-child(2) {
    width: 40%;
    text-align: center;
}



/* tabla familiares */

#tabla_familiares {
    box-sizing: border-box;
    width: 100%;
    /* border: 1px solid red; */
}

#tabla_familiares tr {
    box-sizing: border-box;
    width: 100%;
    /* border: 1px solid red; */
}

#tabla_familiares td:first-child {
    width: 20%;
}

#tabla_familiares td:nth-child(2) {
    width: 10%;
    text-align: center;
}

#tabla_familiares td:nth-child(3) {
    width: 40%;
    text-align: center;
}



/* tabla informes */

#tabla_informes {
    box-sizing: border-box;
    width: 100%;
    /* border: 1px solid red; */
}

#tabla_informes tr td {
    box-sizing: border-box;
    width: 100%;
    /* border: 1px solid red; */
}

#tabla_informes td:first-child {
    width: 20%;
}

#tabla_informes td:nth-child(2) {
    width: 10%;
    text-align: center;
}

#tabla_informes td:nth-child(3) {
    width: 40%;
    text-align: center;
}



/**********************/
/********* Botones *************/

.label-bottom-admin {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    /* border: 1px solid red; */
}


#px_btn_nuevo_admin {
    position: absolute;
    right: 0;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 1.6rem;
    border: none;
    border-radius: 5px;
    color: aliceblue;
    margin-left: 1rem;
    cursor: pointer;
    background-color: blueviolet;
}

#px_btn_nuevo_admin img {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.2rem;
}




/******** SELECT option ********/

#px_select_admin {
    width: 100%;
    padding: 0.4rem;
    border: none;
    border-radius: 5px;
    font-size: 0.8rem;
    outline: none;
    vertical-align: middle;
    /* border: 1px solid rgb(255, 0, 191); */
}

#px_select_admin option {
    font-size: 0.8rem;
}


/************ Datos **************/

.tabla_item_datos {
    box-sizing: border-box;
    width: 55%;
    display: flex;
    margin-left: 1rem;
    padding: 0.6rem;
    border-radius: 0.3rem;
    box-shadow: 2px 2px 3px 0;
    border: 1px solid gray;
}


/********Buscador*****************/

.px_buscador_admin {
    box-sizing: border-box;
    width: 60%;
    height: 20%;
    margin-top: 2rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    border-radius: 0.3rem;
    border: 1px solid gray;
}


.lbl_item {
    font-size: 1.4rem;
    font-weight: 600;
    color: brown;
}

.input_buscar_admin {
    box-sizing: border-box;
    width: 20%;
    margin-left: 1rem;
    /* border: 1px solid greenyellow; */
}

.input_buscar2_admin {
    box-sizing: border-box;
    width: 40%;
    margin-left: 1rem;
    /* border: 1px solid greenyellow; */
}

.input_buscar_admin select {
    box-sizing: border-box;
    width: 90%;
    padding: 0.2rem;
    border: none;
    border-radius: 5px;
    outline: none;
}

/* combo filtro */
.px_filtro_admin {
    box-sizing: border-box;
    width: 40%;
    display: flex;
    /* border: 1px solid blue; */
}

.px_filtro_admin select {
    box-sizing: border-box;
    width: 40%;
    padding: 0.2rem;
    border: none;
    border-radius: 5px;
    outline: none;
}


/*******************************/
/*******************************/


.px_div_3_admin {
    width: 100%;
    /* border: 1px solid red; */
}

.px_div_4_admin {
    width: 100%;
    /* border: 1px solid red; */
}

.px_div_5_admin {
    width: 60%;
    padding: 0.4rem;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
}


/* Encabezado <th> */
/* Espacios intermedios en el th */

#encabezado_px_admin {
    width: 100%;
    border-collapse: collapse;
    font-weight: 600;
}


.div-encabezado_px_admin {
    width: 60%;
    margin-top: 0.4rem;
    padding: 0.4rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
}

/* .tr-encabezado-px {
    border: 1px solid red;
} */


#encabezado_px_admin th:first-child {
    width: 30%;
    font-size: 0.8rem;
    text-align: left;
    /* border: 1px solid red; */
}

#encabezado_px_admin th:nth-child(2) {
    width: 20%;
    font-size: 0.8rem;
    text-align: left;
    /* border: 1px solid red; */
}

#encabezado_px_admin th:nth-child(3) {
    width: 20%;
    font-size: 0.8rem;
    text-align: left;
    /* border: 1px solid red; */
}

#encabezado_px_admin th:nth-child(4) {
    width: 30%;
    font-size: 0.8rem;
    text-align: left;
    /* border: 1px solid red; */
}


#celda_px_admin {
    width: 100%;
    border-collapse: collapse;
}

#celda_px_admin tr {
    padding: 0.4rem;
    border-bottom: 1px solid gray;
    /* border: 1px solid red; */
}

#celda_px_admin tr td  {
    padding: 0.4rem;
    /* border: 1px solid red; */
}

#celda_px_admin th:first-child {
    width: 30%;
    font-size: 0.8rem;
    text-align: left;
    /* border: 1px solid red; */
}

#celda_px_admin th:nth-child(2) {
    width: 20%;
    font-size: 0.8rem;
    text-align: left;
    /* border: 1px solid red; */
}

#celda_px_admin th:nth-child(3) {
    width: 20%;
    font-size: 0.8rem;
    text-align: left;
    /* border: 1px solid red; */
}

#celda_px_admin th:nth-child(4) {
    width: 30%;
    font-size: 0.8rem;
    text-align: left;
    /* border: 1px solid red; */
}

/*-------------------------*/





.div-sinInfo-admin {
    box-sizing: border-box;
    width: 40%;
    display: flex;
    overflow: auto;
    overflow-y: auto;
    margin-right: 1rem;
    /* border: 1px solid rgb(0, 38, 255); */
}

.div-conInfo-admin {
    box-sizing: border-box;
    width: 100%;
    flex-direction: column;
    /* overflow: auto;
    overflow-y: auto; */
    /* border: 1px solid rgb(0, 38, 255); */
}


.div_foto_perfil {
    box-sizing: border-box;
    width: 40%;
    padding: 1rem;
    margin-right: 1rem;
    /* border: 1px solid red; */
}

.px_seleccionado_tabla_admin {
    box-sizing: border-box;
    width: 100%;
    border-radius: 0.3rem;
    /* border: 1px solid gray; */

}

.px_tabla_admin {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
}

.px_tabla_admin #edit_delete {
    width: 100%;
    display: flex;
    /* border: 1px solid red; */
    background-color: rgb(236, 216, 255);
}



.div-avatarPx-admin {
    width: 100%;
    display: flex;
    margin-top: 0.3rem;
    margin-bottom: 0.4rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
}

.div-avatarPx2-admin {
    width: 40%;
    /* border: 1px solid red; */
}

#img_avatar_admin {
    width: 100%;
    padding: 0.4rem;
    /* border: 1px solid red; */
}

#img_avatar_admin img {
    width: 100%;
    border-radius: 0.3rem;
    box-shadow: 2px 2px 3px 0;
}



/*------ Tabla Datos --------*/

.div_px_seleccionado_admin {
    box-sizing: border-box;
    width: 100%;
    border-radius: 0.3rem;
    border: 1px solid gray;
}

#table_pxDatos_admin {
    width: 100%;
    /* border: 1px solid black; */
}


.col1-admin {
    width: 35%;
    padding: 0.4rem;
    padding-left: 0.8rem;
    font-size: 0.8rem;
    font-weight: 600;
    /* border: 1px solid red; */
}

.col2-admin {
    width: 65%;
    font-size: 0.8rem;
    /* border: 1px solid red; */
}



#px_h3_admin {
    box-sizing: border-box;
    width: 50%;
    /* border: 1px solid blue; */
}

#px_h3_admin label {
    box-sizing: border-box;
    width: 50%;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0.2rem;
    margin-left: 0.8rem;
    /* border: 1px solid blue; */
}



#e-d_label_admin {
    box-sizing: border-box;
    width: 50%;
    text-align: right;
    /* border: 1px solid blue; */
}

/***********************************/
/************** foto *****************/


.div-photo-border-sinDatos {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid blue; */
}

.div-photo-border-sinDatos img {
    width: 25%;
    height: 25%;
    border: 1px solid rgb(190, 190, 190);
}

.div-photo-border {
    width: 100%;
    height: 60%;
    /* border: 1px solid blue; */
}


.div-photo-border img {
    width: 100%;
    height: 100%;
    /* border: 1px solid blue; */
}


/**************************/

/**** Celda Paciente Tabla *********/

#td-usuario-admin {
    box-sizing: border-box;
    width: 100%;
    padding: 0.4rem;
    text-align: center;
    /* border: 1px solid blue; */
}

#td-usuario-admin label {
    font-weight: 600;
}

#td-paciente-admin {
    box-sizing: border-box;
    width: 100%;
    padding: 0.4rem;
    margin: 0;
    /* border: 1px solid blue; */
}


/**************************/
/***** TABLA DATOS Y MENU PACIENTES ***/

.div-td-paciente-admin {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    /* border: 1px solid rgb(0, 255, 42); */
}

.div-td-paciente2-admin {
    width: 100%;
    text-align: center;
    /* border: 1px solid rgb(0, 255, 42); */
}

.div-lbl-paciente {
    box-sizing: border-box;
    width: 50%;
    padding: 0;
    margin: 0;
    /* border: 1px solid rgb(234, 0, 255); */
}


/*********** Menú Hamburguesa **********/

#div-menuHamb-admin {
    box-sizing: border-box;
    width: 50%;
    /* border: 1px solid red; */
}

.nav-menuHamb-admin {
    box-sizing: border-box;
    width: 100%;
    text-align: right;
    padding-right: 1rem;
}

/*   Efecto Responsive   */

#ul-menuHamb-admin {
    display: none;
}

#in-menuHamb-admin:checked~ul {
    display: block;
}

#in-menuHamb-admin {
    display: none;
}


.nav-menuHamb-admin label {
    box-sizing: border-box;
    /* display: inline-block;
    border-radius: 3px;
    margin-right: 0.4rem;
    padding: 0 0.2rem 0 0.2rem; */
    font-size: 1.2rem;
    /* text-align: center; */
    font-weight: bold;
    user-select: none;
}

/* Estilo del boton cuando se pasa 
    el mouse por encima*/
.nav-menuHamb-admin label:hover {
    background: grey;
}

/* Estilo del boton cuando el menú está 
    expandido*/
.nav-menuHaamb input:checked~label {
    background: lightgrey;
}

.nav-menuHamb-admin ul {
    margin-top: 0;
    padding: 0;
    width: 100%;
}


#li-menuHamb-admin {
    display: block;
    background: lightgrey;
    margin: 0;
    padding: 0.4rem;
    list-style: none;
    border-bottom: 1px solid grey;
    font-size: 0.8rem;
}

#li-menuHamb-admin a {
    text-decoration: none;
    color: rgb(80, 80, 80);
}

/*Estilo cuando el mouse pasa encima 
    de cada link del menu*/
#li-menuHamb-admin:hover {
    filter: brightness(110%);
}


/*-------- Datos Canalización -------*/

.div-datosCan-admin {
    width: 100%;
    margin-bottom: 0.4rem;
    /* border: 1px solid red; */
}

.div-datosCan1-admin {
    width: 100%;
    padding: 0.4rem;
    font-size: 0.8rem;
    border-radius: 0.3rem;
    border: 1px solid gray;
}

#table-datosCan1-admin tr {
    border-bottom: 1px solid gray;
    /* border: 1px solid red; */
}

#table-datosCan1-admin tr td {
    padding: 0.4rem;
    /* border: 1px solid red; */
}

#td-datosCan1-izq {
    width: 50%;
    font-weight: 600;
}

#td-datosCan1-der {
    width: 50%;
}


