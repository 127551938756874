

/* ---- ADD COLEGA --------*/
/* ----  ------------ --------*/


.div-1-colega {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}

.div_2_colega {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(12, 22, 161); */
}

#lbl-enSesion_colega {
  font-weight: 600;
}

.div_lbl_colega {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  /* border: 1px solid rgb(0, 23, 128); */
}

.div_lbl_colega label {
  font-size: 2rem;
  font-weight: 600;
}


/***** Div Datos Paciente *******/

.div-datos-colega {
  box-sizing: border-box;
  width: 50%;
  margin: auto;
  margin-bottom: 3rem;
  /* border: 1px solid rgb(0, 23, 128); */
}

.div_content_foto_colega {
  box-sizing: border-box;
  width: 100%;
  height: 30%;
  display: flex;
  /* border: 1px solid rgb(51, 255, 0); */
}

.div-foto-colega {
  box-sizing: border-box;
  text-align: center;
  width: 30%;
  /* border: 1px solid red; */
}

.div-foto-colega img {
  box-sizing: border-box;
  text-align: center;
  padding: 0.3rem;
  width: 100%;
  height: 100%;
  /* border: 1px solid rgb(255, 0, 255); */
}



.div_btn_foto_colega {
  box-sizing: border-box;
  padding: 1rem;
  width: 70%;
  display: flex;
  align-items: end;
  justify-content: left;
  /* border: 1px solid rgb(0, 89, 255); */
}


/****** TABLA DATOS *******/

.div_tabla_colega {
  box-sizing: border-box;
  /* border: 1px solid #002168; */
}

.table-colega {
  box-sizing: border-box;
  width: 100%;
  border-collapse: separate;
  border-spacing: 5px 10px;
  /* border: 1px solid #006809; */
}


/****** TR ********/

/****** TD ********/

.colega-td-izq {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  background-color: rgb(255, 221, 180);
}

.colega-td-der1 {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}

.colega-td-der2 {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}

.colega-td-der3 {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
  /* border-bottom: solid 1px #ccc; */
}

/****** TD select *******/

.colega-td-select {
  box-sizing: border-box;
  width: 20%;
  height: 2rem;
}

/***** INPUT ******/

.colega-td-der1 input {
  box-sizing: border-box;
  border: 1px solid gray;
  /* outline: none; */
  border-radius: 0.3rem;
  font-size: 0.8rem;
  padding: 0.2rem;
  padding-left: 0.4rem;
  /* background-color: transparent; */
}

.colega-td-der2 input {
  box-sizing: border-box;
  border: 1px solid gray;
  /* outline: none; */
  border-radius: 0.3rem;
  font-size: 0.8rem;
  padding: 0.2rem;
  padding-left: 0.4rem;
  /* background-color: transparent; */
}

.colega-td-der3 input {
  box-sizing: border-box;
  border: 1px solid gray;
  /* outline: none; */
  border-radius: 0.3rem;
  font-size: 0.8rem;
  padding: 0.2rem;
  padding-left: 0.4rem;
  /* background-color: transparent; */
}


/********* RADIO BUTTOM **********/

.radio-container label {
  font-size: 0.8rem;
  color: #494949;
  margin-right: 0.6rem;
}


/************************/

/*  ID Select */

#select-sexo-colega,
#select-escolaridad-colega,
#select-pref_sex-colega,
#select-edo-civil-colega,
#select-titulo-colega,
#select-edo-colega,
#select-pais-colega {
  width: 100%;
  font-size: 0.8rem;
  padding: 0.2rem;
  outline: none;
  color: #494949;
  border-radius: 0.3rem;
}


#select-sexo-colega option,
#select-escolaridad-colega option,
#select-pref_sex-colega option,
#select-edo-civil-colega option,
#select-edo-colega option,
#select-pais-colega option {
  font-size: 0.8rem;
  color: #000000;
}

/* ----------------- */


/*---------------------------*/
/* Div botones: ENVIAR CANCELAR*/

/*   div Padre   autoreg y botones   */

.div-autoregistro-botones-colega {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 2rem;
  /* border: 1px solid blue; */
}

.div-btn-alineacion-colega {
  box-sizing: border-box;
  margin: auto;
  width: 20%;
  display: flex;
  /* border: 1px solid blue; */
}


/* Div botones: ENVIAR CANCELAR*/
.div-colega-enviar {
  box-sizing: border-box;
  width: 50%;
  margin: auto;
  /* border: 1px solid rgb(5, 101, 165); */
}

.div-colega-cancelar {
  box-sizing: border-box;
  width: 50%;
  margin: auto;
  /* border: 1px solid rgb(5, 101, 165); */
}


#btn-enviar-colega,
#btn-cancelar-colega {
  border-radius: 0.4rem;
  border: 1px solid gray;
  cursor: pointer;
}

/*----------------------------------------*/



