/* -------- CANALIZAR -------- */

.calizar_div_1 {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}


.calizar_div_2 {
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  margin-bottom: 1rem;
  /* border: 1px solid rgb(161, 12, 141); */
}

.calizar_sesion {
  box-sizing: border-box;
  width: 20%;
  display: flex;
  align-items: center;
  /* border: 1px solid blue; */
}

#lbl-enSesion {
  font-weight: 600;
}

.div_canalizar_label {
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  /* border: 1px solid green; */
}


/*******************************/

.div-canalizar-admin {
  width: 60%;
  margin: auto;
  /* border: 1px solid green; */
}


.div_titulo_canalizar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0.4rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid rgb(162, 158, 202);
}


#lbl_px_canalizar {
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 600;
  /* border: 1px solid red; */
}



/* ------------- */


.div_select_canalizar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
}

.div-buscar-px-colega {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  /* border: 1px solid rgb(81, 0, 128); */
}

#div-buscar-px-canalizar {
  box-sizing: border-box;
  width: 40%;
  display: flex;
  align-items: center;
  /* border: 1px solid green; */
}


/*------- SELECT --------*/

.select_canalizar_px {
  box-sizing: border-box;
  width: 100%;
  padding: 0.4rem;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  border: none;
  outline: none;
}



/*   label select      */

.lbl_px_select {
  box-sizing: border-box;
  padding-right: 0.4rem;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0.4rem;
  /* border: 1px solid green; */
}


/************ select colega *****/

.select_canalizar_colega {
  box-sizing: border-box;
  width: 100%;
  padding: 0.4rem;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  border: none;
  outline: none;
}


.lbl_colega_select {
  box-sizing: border-box;
  padding-right: 0.4rem;
  padding-left: 1rem;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0.4rem;
  /* border: 1px solid green; */
}


/*************** Btn Canalizar *************/

#div-canalizar {
  box-sizing: border-box;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: end;
  /* border: 1px solid rgb(36, 128, 0); */
}

#btn-canalizar {
  box-sizing: border-box;
  width: 6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 0.8rem;
  border-radius: 5px;
  color: aliceblue;
  margin-left: 1rem;
  cursor: pointer;
  background-color: blueviolet;
}

#btn-canalizar img {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
}

/*****************/

/*************** Btn add colega *************/

.div_btn_addColega {
  box-sizing: border-box;
  width: 40%;
  display: flex;
  align-items: center;
  /* border: 1px solid rgb(128, 51, 0); */
}

.div_btn_addColega label {
  box-sizing: border-box;
  padding-top: 0.2rem;
  font-size: 0.8rem;
  padding-left: 1rem;
}


#btn-addColega {
  box-sizing: border-box;
  width: 6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 0.8rem;
  border-radius: 5px;
  color: aliceblue;
  margin-left: 1rem;
  cursor: pointer;
  background-color: blueviolet;
}

#btn-addColega img {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
}



/* ---------DISPLAYS------------ */
/* Listado de pacientes */

.calizar_div_3a {
  box-sizing: border-box;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  /* border: 1px solid black; */
}

.calizar_div_3 {
  box-sizing: border-box;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  /* border: 1px solid black; */
}

.div_px_lista_canalizar_a {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  overflow: auto;
  overflow-y: auto;
  margin-right: 1rem;
  /* border: 1px solid red; */
}

.div_px_lista_canalizar {
  box-sizing: border-box;
  width: 60%;
  overflow: auto;
  overflow-y: auto;
  margin-right: 1rem;
  /* border: 1px solid red; */
}


.div_EncabezadoCol_canalizar {
  width: 100%;
  border: 0.3rem;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid rgb(162, 158, 202);
}

.div_FilasCol2_canalizar {
  width: 100%;
  margin-top: 0.4rem;
  padding: 0.4rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
}


/*  DIV PACIENTE   */

.px_tabla_encabezados {
  box-sizing: border-box;
  width: 60%;
  margin-right: 1rem;
  /* border: 1px solid red; */
}

.div-table_encabezado_canalizar {
  width: 100%;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid rgb(162, 158, 202);
}

.px_tabla_canalizar {
  box-sizing: border-box;
  width: 40%;
  /* border: 1px solid red; */
}

/******** Sin datos ********/

.div-tabla-canalizar-sinDatos {
  box-sizing: border-box;
  width: 100%;
  /* border: 1px solid rgb(225, 0, 255); */
}

.div_titulo_motivo_canalizar {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 0.4rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid rgb(162, 158, 202);
}

.div-label-sinDatos {
  display: flex;
  justify-content: center;
}

#label-sinDatos {
  margin-top: 3rem;
  font-size: 0.8rem;
}

/*************************/

.px_psi_canalizar {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  /* border: 1px solid rgb(0, 255, 0); */
}

.psi_canalizar {
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  margin-right: 0.1rem;
  /* border: 1px solid rgb(0, 47, 255); */
}

.px_canalizar {
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  margin-left: 0.1rem;
  /* border: 1px solid rgb(0, 47, 255); */
}

/**********************************/


/*********************/
/******* Input Buscador ********/

.div_titulo_px_canalizados {
  width: 60%;
  margin: auto;
  padding: 0.4rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid rgb(162, 158, 202);
}

.div-btn-pacCanalizados {
  width: 60%;
  margin: auto;
  display: flex;
  margin-bottom: 2rem;
  /* border: 1px solid red; */
}

.div_pax_canalizados {
  width: 50%;
}

.div_pax_canalizados label {
  padding-right: 1.4rem;
}

.div_colegas_atienden {
  width: 50%;
}

.div_colegas_atienden label {
  padding-right: 1.4rem;
}


.input_buscar_canal {
  box-sizing: border-box;
  position: relative;
  width: 25%;
  margin: auto;
  margin-bottom: 1rem;
  /* border: 1px solid red; */
}


.px_filtro_canal {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 50%;
  /* border: 1px solid red; */
}

#px_select_canal {
  width: 50%;
  padding: 0.4rem;
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  outline: none;
  vertical-align: middle;
  color: #5e5e5e;
}

#px_select_canal option {
  font-size: 0.8rem;
  color: #363636;
}




/*********DIV motivo canalizar **********/

.tablasSelec_canalizar {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: 1px solid rgb(0, 68, 255); */
}

/* barra morada */
.div_motivo_canalizar {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 0.4rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid rgb(162, 158, 202);
}

#div_titulo_can {
  box-sizing: border-box;
  width: 50%;
  /* border: 1px solid red; */
}

#div_btn_finalizar_can {
  box-sizing: border-box;
  width: 50%;
  display: flex;
  justify-content: end;
  text-align: right;
  margin-right: 0.6rem;
}


/**** Table Fin Canalización ****/

.table_finCanalizacion {
  box-sizing: border-box;
  width: 100%;
  margin: auto;
}

.table_finCanalizacion tr {
  box-sizing: border-box;
  width: 100%;
  font-size: 1rem;
}

#tdIzq_finCan {
  width: 30%;
  text-align: end;
  padding-right: 0.4rem;
  font-weight: 600;
}

#tdDer_finCan {
  width: 70%;
  text-align: left;
  padding-left: 0.4rem;
}


/**************************/



/* foto y nombre */
.div_datosPxCol_canalizar {
  width: 100%;
  flex-direction: column;
  padding: 0.4rem;
  background-color: rgb(255, 216, 168);
  border-radius: 0.3rem;
  border: 1px solid gray;
}

/* Colega */
#div_datosCol_canalizar {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
  padding: 0.4rem;
  border-radius: 0.3rem;
  background-color: rgb(236, 216, 255);
  border: 1px solid rgb(162, 158, 202);
}

.div_titulo_motivoCan_colega {
  width: 40%;
  /* border: 1px solid red; */
}

.div_btn_finCan_colega {
  width: 60%;
  display: flex;
  justify-content: end;
  /* border: 1px solid red; */
}

#div_fotoCol_canalizar img {
  box-sizing: border-box;
  width: 4rem;
  height: 4rem;
  padding: 0.2rem;
}

#div_nomCol_canalizar {
  width: 70%;
  margin-left: 1rem;
  /* border: 1px solid rgb(207, 42, 212); */
}

/* Paciente */
#div_datosPx_canalizar {
  display: flex;
  align-items: center;
  padding: 0.4rem;
  background-color: bisque;
  border-radius: 0.3rem;
  border: 1px solid gray;
}

#div_fotoPx_canalizar {
  box-sizing: border-box;
  width: 30%;
  padding: 0.2rem;
  text-align: center;
}

#div_fotoPx_canalizar img {
  box-sizing: border-box;
  width: 4rem;
  height: 4rem;
  box-shadow: 4px 4px 6px 0;
}

#div_nomPx_canalizar {
  width: 70%;
  display: flex;
  margin-left: 1rem;
  padding: 0.4rem;
}

#lbl-paciente1-canalizar {
  font-weight: 600;
  font-size: 0.8rem;
  padding-right: 0.6rem;
}

#lbl-pacCanalizar {
  font-weight: 600;
  font-size: 0.8rem;
  padding-right: 0.6rem;
}

#lbl-pacCanalizar2 {
  font-size: 0.8rem;
  padding-right: 0.6rem;
}



/* Tabla Motivo canalizar */
.div-motivo-canalizar {
  box-sizing: border-box;
  width: 100%;
  padding: 0.4rem;
  margin-top: 0.4rem;
  border-radius: 0.3rem;
  background-color: rgb(255, 216, 168);
  border: 1px solid gray;
}


/* tabla motivo */

.table_motivo_seleccionado {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0%;
  margin: 0%;
}



/***************************/
/***** CELDAS TABLA MOTIVO ********/

.celda-motivo-canalizar-left {
  box-sizing: border-box;
  width: 30%;
  font-size: 0.8;
  padding: 0.2rem;
  /* border: 1px solid rgb(255, 30, 0); */

}

.celda-motivo-canalizar-right {
  box-sizing: border-box;
  width: 50%;
  font-size: 0.8;
  padding: 0.2rem;
  /* border: 1px solid rgb(255, 30, 0); */

}



/****************************/
/****** IMAGEN PERFIL ********/

.img-avatar-canalizar {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
}

.img-avatar-canalizar img {
  box-sizing: border-box;
  width: 15%;
  height: 15%;
  /* border: 1px solid rgb(255, 30, 0); */
}


#lbl-paciente-canalizar,
#lbl-psicologo-canalizar,
#lbl-psicologo2-canalizar {
  font-size: 0.8rem;
}


#lbl-psicologo-canalizar {
  font-weight: 600;
}

.label-fecha-canalizar,
.label-motivo-canalizar,
.label-comentario-canalizar {
  padding-left: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
}

#lbl-fecha-canalizar,
#lbl-motivo-canalizar,
#lbl-comentario-canalizar {
  font-size: 0.8rem;
}



/********** Paciente canalizado Tabla ***************/

/********* TABLA DISPLAY DATA ********/

#table_encabezado_canalizar {
  box-sizing: border-box;
  width: 100%;
  border-collapse: collapse;
  font-weight: 600;
  /* border: 1px solid rgb(72, 255, 0); */
}

/* Encabezado <th> */
/* Espacios intermedios en el th */

.tr-encabezado-canalizar {
  width: 100%;
  font-size: 0.8rem;
  /* border: 1px solid rgb(72, 255, 0); */
}

.tr-encabezado-canalizar th:first-child {
  width: 30%;
  padding: 0.6rem;
  /* border: 1px solid rgb(55, 0, 255); */
}

.tr-encabezado-canalizar th:nth-child(2) {
  width: 50%;
  /* border: 1px solid rgb(0, 153, 255); */
}

.tr-encabezado-canalizar th:nth-child(3) {
  width: 20%;
  text-align: center;
  /* border: 1px solid rgb(119, 0, 255); */
}


/*-------------------------*/

/*    Tabla Filas canalizar     */
/* Espacio entre celdas de la fila: radio button */


.div-filas_canalizar {
  width: 100%;
  margin-top: 0.4rem;
  padding: 0.4rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
}


#table_filas_canalizar {
  box-sizing: border-box;
  width: 100%;
  font-size: 1rem;
  border-collapse: collapse;
  /* border: 1px solid rgb(107, 97, 7); */
}

.filas_canalizar td:first-child {
  width: 30%;
  /* border: 1px solid #307503; */
}

.filas_canalizar td:nth-child(2) {
  width: 70%;
  /* border: 1px solid #ff0000; */
}


/*     Tabla DisplayData2     */

#table_displadata2_can {
  width: 100%;
  /* border: 1px solid #ff0000; */
}

#filas_diaplaydata2 td:first-child {
  width: 70%;
  /* border: 1px solid #0051ff; */
}

#filas_diaplaydata2 td:nth-child(2) {
  width: 30%;
  text-align: center;
  /* border: 1px solid #ff00dd; */
}


/*   estilo radio button    */

label>input[type="radio"] {
  display: none;
}

label>input[type="radio"]+*::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
}

label>input[type="radio"]:checked+* {
  color: teal;
}

label>input[type="radio"]:checked+*::before {
  background: radial-gradient(teal 0%, teal 40%, transparent 50%, transparent);
  border-color: teal;
}


.div-NoPax-can {
  width: 100%;
  padding: 1rem;
  margin: auto;
  text-align: center;
  /* border: 1px solid #696969; */
}



/*-------- MODAL ----------*/
/* ---------- MOTIVO CANALIZACIÓN -------- */
/*------------------/*------------------*/

.table-registro-motivo {
  width: 100%;
  /* border: 1px solid #696969; */
}

.table-registro-motivo td:first-child {
  width: 30%;
  font-size: 0.8rem;
  background-color: bisque;
}

.table-registro-motivo td:nth-child(2) {
  width: 70%;
  font-size: 0.8rem;
  background-color: rgb(252, 240, 226);
}


#td-pac-canalizar {
  text-align: left;
}

/* label  derecha MODAL*/
.lbl-px-canalizar {
  padding: 1rem;
  color: #363636;
  cursor: pointer;
}

#td-txt-canalizar {
  padding: 0.4rem;
}

#td-time-canalizar {
  padding: 0.4rem;
  text-align: left;
  border: none;
  outline: none;
}

#td-time-canalizar input {
  width: 40%;
}


.textarea_canalizar {
  font-size: 0.8rem;
  border-radius: 0.4rem;
  padding-left: 0.6rem;
}

/*----------------------*/