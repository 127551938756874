

/*-----------------------*/
/* Recomendacio css */
/*-----------------------*/


.faq-container-Recomender {
    width: 100%;
    /* border: 1px solid red; */
}

.faq-container-recom {
    box-sizing: border-box;
    width: 100%;
    /* height: 100%; */
    margin-top: 0.4rem;
    /* overflow: auto;
    overflow-y: auto; */
    /* border: 1px solid red; */
}

#p-button-recom {
    text-align: right;
    /* border: 1px solid red; */
}

#p-button-recom button {
    margin-right: 1rem;
    border-radius: 0.3rem;
    border: 1px solid rgb(124, 124, 124);
}

.div-button-recom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 2rem;
    margin-bottom: 1rem;
    /* border: 1px solid red; */
}

.div-button-nvoRecom {
    width: 50%;
    text-align: right;
    /* border: 1px solid rgb(5, 102, 18); */
}

.div-button-nvoRecom button {
    width: 60%;
    border-radius: 0.3rem;
    font-size: 0.8rem;
    padding: 0.2rem;
    border: 1px solid rgb(124, 124, 124);
}






