@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;800&display=swap');




/*   Está en el index.css 

.div-header {
    
}   */



/*No desbordar fuera de DIV
    overflow: hidden;
*/

.logo {
    width: 30%;
    height: 100%;
    /* border: 1px solid red; */
}

.logo img {
    height: 90%;
    width: 100%;
    cursor: pointer;
    padding-left: 1.1rem;
    transition: all 0.3s;
}

.logo img:hover {
    transform: scale(1.2);
}


/* ----- Nav bar -------*/

.d-grid {
    box-sizing: border-box;
    width: 80%;
    margin-right: 1rem;
    align-items: center;
    /* border: 1px solid red; */
}

.d-grid button {
    box-sizing: border-box;
    font-size: 0.9rem;
    width: 8rem;
    height: 2rem;
    border: none;
    color: rgb(75, 75, 75);
    background-color: beige;
    /* border: 1px solid red; */
}

.d-grid button:hover {
    background-color: bisque;
}

a #img_profile {
    width: 2rem;
}

a #img_colegas {
    width: 2rem;
    margin-right: 0.2rem;
}









